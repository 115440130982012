import React from 'react';
import PropTypes from 'prop-types';
import { urls, financialStandardsQualification } from '../../../../constants';
import QuestionFlow from '../../../QuestionFlow';
import RequestExempt from '../../../../containers/RequestExempt';
import ApplicationGuideLink from '../../../ApplicationGuideLink';
import styles from './DetermineFinancialEligibility.module.scss';

const DOES_NOT_QUALIFY = financialStandardsQualification.DOES_NOT_QUALIFY;
const QUALIFIES_US = financialStandardsQualification.QUALIFIES_US;
const QUALIFIES_US_PREM = financialStandardsQualification.QUALIFIES_US_PREM;
const QUALIFIES_INTL = financialStandardsQualification.QUALIFIES_INTL;
const QUALIFIES_INTL_PERM = financialStandardsQualification.QUALIFIES_INTL_PERM;

const pennyStockQuestions = [
  {
    fieldName: 'hasMarketCapOfTenMillion',
    title: 'Does the company have a Market Cap of at least $10 million USD?',
    result: {
      true: 'Yes, has a Market Cap of at least $10 million USD.',
      false: 'No, does not have a Market Cap of at least $10 million USD.'
    },
    next: {
      OTCQX_US: {
        true: 'hasTenPercentFloat',
        false: DOES_NOT_QUALIFY
      },
      OTCQX_BANKS: {
        true: 'hasTenPercentFloat',
        false: DOES_NOT_QUALIFY
      }
    }
  },
  {
    fieldName: 'hasTenPercentFloat',
    title: () => <>Does each class of securities listed in this Application have a <a href={urls.GLOSSARY_PUBLIC_FLOAT} target='_blank' rel='noopener noreferrer'>Public Float</a> of at least 10%?</>,
    result: {
      true: () => <>Yes, primary security has at least 10% in <a href={urls.GLOSSARY_PUBLIC_FLOAT} target='_blank' rel='noopener noreferrer'>public float</a>.</>,
      false: () => <>No, primary security does not have at least 10% in <a href={urls.GLOSSARY_PUBLIC_FLOAT} target='_blank' rel='noopener noreferrer'>public float</a>.</>
    },
    next: {
      OTCQX_US: {
        true: 'hasFiftyBeneficialShareholders',
        false: 'willApplyForFloatExemption'
      },
      OTCQX_BANKS: {
        true: 'hasFiftyBeneficialShareholders',
        false: 'willApplyForFloatExemption'
      }
    }
  },
  {
    fieldName: 'hasFiftyBeneficialShareholders',
    title: () => <>Are there at least 50 <a href={urls.GLOSSARY_BENEFICIAL_SHAREHOLDER} target='_blank' rel='noopener noreferrer'>beneficial shareholders</a> that hold at least 100 shares each?</>,
    result: {
      true: () => <>Yes, has at least 50 <a href={urls.GLOSSARY_BENEFICIAL_SHAREHOLDER} target='_blank' rel='noopener noreferrer'>beneficial shareholders</a> holding at least 100 shares each.</>,
      false: () => <>No, does not have 50 <a href={urls.GLOSSARY_BENEFICIAL_SHAREHOLDER} target='_blank' rel='noopener noreferrer'>beneficial shareholders</a> holding at least 100 shares each.</>
    },
    next: {
      OTCQX_US: {
        conditionalNext: {
          answer: true,
          condition: 'hasBeenInOperationForThreeYears',
          result: QUALIFIES_US
        },
        true: 'hasBidPriceOfFourDollars',
        false: DOES_NOT_QUALIFY
      },
      OTCQX_BANKS: {
        true: 'hasTotalAssetsOfOneHundredMillionDollars',
        false: DOES_NOT_QUALIFY
      },
      OTCQX_INTL: {
        true: 'hasBeenInOperationForFiveYears',
        false: DOES_NOT_QUALIFY
      }
    }
  },
  {
    fieldName: 'hasTotalAssetsOfOneHundredMillionDollars',
    title: 'Does the Bank have Total Assets of at least $100 million USD?',
    result: {
      true: 'Yes, the Bank has Total Assets of at least $100 million USD.',
      false: 'No, does not have required Total Assets of $100 million USD.'
    },
    next: {
      OTCQX_BANKS: {
        true: 'hasShareholderEquityOfFourMillion',
        false: DOES_NOT_QUALIFY
      }
    }
  },
  {
    fieldName: 'willApplyForFloatExemption',
    title: 'If your Company has securities with a freely traded Public Float below 10% but above 5% of the total shares issued and outstanding and a market value of Public Float of at least $2 million, or if your Company has a separate class of securities traded on a national exchange, OTC Markets Group may consider providing an exemption to the 10% Public Float Requirement. If you would like to request for an exemption, please provide details about the company’s current Public Float data and request such exemption below.',
    result: {
      true: 'Yes, applied for exemption.',
      false: 'No,will not apply for exemption.'
    },
    next: {
      OTCQX_US: {
        component: true,
        true: 'hasFiftyBeneficialShareholders',
        false: DOES_NOT_QUALIFY
      },
      OTCQX_BANKS: {
        component: true,
        true: 'hasFiftyBeneficialShareholders',
        false: DOES_NOT_QUALIFY
      }
    },
    component: (props) => <RequestExempt {...props} />
  }
];
const otcqxIntlQuestions = [
  {
    fieldName: 'hasGlobalMarketCapOfTenMillion',
    title: 'Does the company have a Global Market Capitalization of at least $10 million USD?',
    result: {
      true: 'Yes, has a Market Cap of at least $10 million.',
      false: 'No, does not have a Market Cap of at least $10 million.'
    },
    next: {
      OTCQX_INTL: {
        true: 'hasFiftyBeneficialShareholders',
        false: DOES_NOT_QUALIFY
      }
    }
  },
  {
    fieldName: 'hasBeenInOperationForFiveYears',
    title: 'Has the company been in operation for at least 5 years?',
    result: {
      true: 'Yes, has been in operations for at least 5 years.',
      false: 'No, has not been in operations for at least 5 years.'
    },
    next: {
      OTCQX_INTL: {
        true: 'hasAvgWeekVolPrevSixMonths',
        false: QUALIFIES_INTL
      }
    }
  },
  {
    fieldName: 'hasAvgWeekVolPrevSixMonths',
    title: 'Does the underlying security have an average weekly trading volume of at least 200,000 shares or $1 million USD over the last 6 months in its primary market?',
    result: {
      true: 'Yes, has an average weekly trading volume of 200,000 shares or $1 million USD.',
      false: 'No, does not have an average weekly trading volume of 200,000 shares or $1 million USD.'
    },
    next: {
      OTCQX_INTL: {
        true: 'hasGlobalMarketCapOfOneBillion',
        false: QUALIFIES_INTL
      }
    }
  },
  {
    fieldName: 'hasGlobalMarketCapOfOneBillion',
    title: 'Does the company have a Global Market Capitalization of at least $1 billion USD?',
    result: {
      true: 'Yes, has a Global Market Capitalization of at least $1 billion USD.',
      false: 'No, does not have a Global Market Capitalization of at least $1 billion USD.'
    },
    next: {
      OTCQX_INTL: {
        true: QUALIFIES_INTL_PERM,
        false: QUALIFIES_INTL
      }
    }
  }
];

const otcqxusQuestions = [
  {
    fieldName: 'hasBidPriceOfFourDollars',
    title: 'Does the company have a bid price of at least $4 USD?',
    result: {
      true: 'Yes, has a bid price of at least $4 USD.',
      false: 'No, does not have a bid price of at least $4 USD.'
    },
    next: {
      OTCQX_US: {
        true: 'hasShareholderEquityOfFourMillion',
        false: QUALIFIES_US
      },
      OTCQX_BANKS: {
        true: 'hasOneHundredBeneficialShareholders',
        false: QUALIFIES_US
      }
    }
  },
  {
    fieldName: 'hasShareholderEquityOfFourMillion',
    title: `Does the company have shareholder's equity of at least $4 million USD?`,
    result: {
      true: `Yes, has shareholder's equity of at least $4 million USD.`,
      false: `'No, does not have shareholder's equity of at least $4 million USD.`
    },
    next: {
      OTCQX_US: {
        true: 'hasFiveHundredThousandPublicFloat',
        false: QUALIFIES_US
      },
      OTCQX_BANKS: {
        conditionalNext: {
          answer: true,
          condition: 'hasBeenInOperationForThreeYears',
          result: QUALIFIES_US
        },
        true: 'hasBidPriceOfFourDollars',
        false: DOES_NOT_QUALIFY
      }
    }
  },
  {
    fieldName: 'hasFiveHundredThousandPublicFloat',
    title: `Does the company have a public float of at least 500,000 shares?`,
    result: {
      true: 'Yes, has a public float of at least 500,000 shares.',
      false: 'No, does not have a public float of at least 500,000 shares.'
    },
    next: {
      OTCQX_US: {
        true: 'hasOneHundredBeneficialShareholders',
        false: QUALIFIES_US
      },
      OTCQX_BANKS: {
        true: 'hasMarketValueMarketCap',
        false: QUALIFIES_US
      }
    }
  },
  {
    fieldName: 'hasMarketValueMarketCap',
    title: 'Does the Company have: (a) Market Value of Public Float of at least $15 million USD and (b) Market Cap of at least $50 million USD?',
    result: {
      true: 'Yes, the Company does have (a) Market Value of Public Float of at least $15 million USD and (b) Market Cap of at least $50 million USD',
      false: 'No, the Company does not have (a)Market Value of Public Float of at least $15 million USD and (b) Market Cap of at least $50 million USD'
    },
    next: {
      OTCQX_BANKS: {
        true: QUALIFIES_US_PREM,
        false: 'hasNetIncomeStandard'
      }
    }
  },
  {
    fieldName: 'hasNetIncomeStandard',
    title: 'Does the Company have: (a) Market Value of Public Float of at least $1 million USD, (b) Net Income of at least $750,000 USD and (c) Market Cap of at least $10 million USD?',
    result: {
      true: 'Yes, the Company does have (a) Market Value of Public Float of at least $1 million USD, (b) Net Income of at least $750,000 USD and (c) Market Cap of at least $10 million USD.',
      false: 'No, the Company does not have (a) Market Value of Public Float of at least $1 million USD, (b) Net Income of at least $750,000 USD and (c) Market Cap of at least $10 million USD.'
    },
    next: {
      OTCQX_BANKS: {
        true: QUALIFIES_US_PREM,
        false: QUALIFIES_US
      }
    }
  },
  {
    fieldName: 'hasOneHundredBeneficialShareholders',
    title: () => <>Does the company have at least 100 <a href={urls.GLOSSARY_BENEFICIAL_SHAREHOLDER} target='_blank' rel='noopener noreferrer'>beneficial shareholders</a> who own at least 100 shares?</>,
    result: {
      true: () => <>Yes, has at least 100 <a href={urls.GLOSSARY_BENEFICIAL_SHAREHOLDER} target='_blank' rel='noopener noreferrer'>beneficial shareholders</a> who hold at least 100 shares each.</>,
      false: () => <>No, does not have at least 100 <a href={urls.GLOSSARY_BENEFICIAL_SHAREHOLDER} target='_blank' rel='noopener noreferrer'>beneficial shareholders</a> who hold at least 100 shares each.</>
    },
    next: {
      OTCQX_US: {
        true: 'publicFLoatMarketValue',
        false: QUALIFIES_US
      },
      OTCQX_BANKS: {
        true: 'hasFiveHundredThousandPublicFloat',
        false: QUALIFIES_US
      }
    }
  },
  {
    fieldName: 'publicFLoatMarketValue',
    title: `Select range for the company's Market Value of Public Float`,
    options: [
      {
        value: 'GREATER_THAN_FIFTEEN_MILLION',
        text: 'Greater than $15 million'
      },
      {
        value: 'BETWEEN_ONE_AND_FIFTEEN_MILLION',
        text: 'Greater than or Equal to $1 million but less than $15 million'
      },
      {
        value: 'LESS_THAN_ONE_MILLION',
        text: 'Less than $1 million'
      }
    ],
    result: {
      GREATER_THAN_FIFTEEN_MILLION: 'Market Value of Public Float is greater than $15 million.',
      BETWEEN_ONE_AND_FIFTEEN_MILLION: 'Market Value of Public Float is greater than or equal to $1 million but less than $15 million.',
      LESS_THAN_ONE_MILLION: 'Market Value of Public Float is less than $1 million.'
    },
    next: {
      OTCQX_US: {
        GREATER_THAN_FIFTEEN_MILLION: 'hasMarketCapOfFiftyMillion',
        BETWEEN_ONE_AND_FIFTEEN_MILLION: 'hasNetIncomeOfSevenFifty',
        LESS_THAN_ONE_MILLION: QUALIFIES_US
      }
    }
  },
  {
    fieldName: 'hasMarketCapOfFiftyMillion',
    title: `Does the company have a Market Cap of at least $50 million USD?`,
    result: {
      true: 'Yes, has a Market Cap of at least $50 million USD.',
      false: 'No, does not have a market cap of at least $50 million USD.'
    },
    next: {
      OTCQX_US: {
        true: QUALIFIES_US_PREM,
        false: QUALIFIES_US
      }
    }
  },
  {
    fieldName: 'hasNetIncomeOfSevenFifty',
    title: `Does the company have Net Income of at least $750,000 USD?`,
    result: {
      true: 'Has Net Income of at least $750,000 USD.',
      false: 'Does not have Net Income of at least $750,000 USD.'
    },
    next: {
      OTCQX_US: {
        true: QUALIFIES_US_PREM,
        false: QUALIFIES_US
      }
    }
  }
];

const questions = [
  {
    firstQuestion: true,
    fieldName: 'hasBeenInOperationForThreeYears',
    title: 'Has the company been in operation for at least 3 years?',
    result: {
      true: 'Yes, has been in operations for at least 3 years.',
      false: 'No, has not been in operations for at least 3 years.'
    },
    next: {
      OTCQX_US: {
        true: 'hasTwoMillionNTA',
        false: 'hasFiveMillionNTA'
      },
      OTCQX_BANKS: {
        true: 'hasTwoMillionNTA',
        false: 'hasFiveMillionNTA'
      },
      OTCQX_INTL: {
        true: 'hasTwoMillionNTA',
        false: 'hasFiveMillionNTA'
      }
    }
  },
  {
    fieldName: 'hasTwoMillionNTA',
    title: 'Does the company have at least $2 million USD in Net Tangible Assets?',
    subTitle: () => <p>See OTC Market's definition of <a href={urls.GLOSSARY_NTA} target='_blank' rel='noopener noreferrer'>Net Tangible Assets</a>.</p>,
    result: {
      true: () => <>Yes, has at least $2 million in <a href={urls.GLOSSARY_NTA} target='_blank' rel='noopener noreferrer'>Net Tangible Assets</a>.</>,
      false: () => <>No, does not have at least $2 million USD in <a href={urls.GLOSSARY_NTA} target='_blank' rel='noopener noreferrer'>Net Tangible Assets</a>.</>
    },
    next: {
      OTCQX_US: {
        true: 'hasMinBidPriceOfTwentyFiveCents',
        false: 'hasRevenueOfSixMillionInPastThreeYears'
      },
      OTCQX_BANKS: {
        true: 'hasBidPriceOfOneDollar',
        false: 'hasRevenueOfSixMillionInPastThreeYears'
      },
      OTCQX_INTL: {
        true: 'hasMinBidPriceOfTwentyFiveCents',
        false: 'hasRevenueOfSixMillionInPastThreeYears'
      }
    }
  },
  {
    fieldName: 'hasBidPriceOfOneDollar',
    title: 'Does the company have a bid price of at least $1 USD?',
    result: {
      true: 'Yes, has a bid price of at least $1 USD.',
      false: 'No, does not have a bid price of at least $1 USD.'
    },
    next: {
      OTCQX_BANKS: {
        true: 'hasMarketCapOfTenMillion',
        false: DOES_NOT_QUALIFY
      }
    }
  },
  {
    fieldName: 'hasRevenueOfSixMillionInPastThreeYears',
    title: 'Does the company have revenue of at least $6 million USD for the past 3 years?',
    result: {
      true: 'Yes, has revenue of at least $6 million USD for the past 3 years.',
      false: 'No, does not have revenue of at least $6 million USD for the past 3 years.'
    },
    next: {
      OTCQX_US: {
        true: 'hasMinBidPriceOfTwentyFiveCents',
        false: 'hasBidPriceOfFiveDollars'
      },
      OTCQX_BANKS: {
        true: 'hasBidPriceOfOneDollar',
        false: 'hasBidPriceOfFiveDollars'
      },
      OTCQX_INTL: {
        true: 'hasMinBidPriceOfTwentyFiveCents',
        false: 'hasBidPriceOfFiveDollars'
      }
    }
  },
  {
    fieldName: 'hasFiveMillionNTA',
    title: 'Does the company have at least $5 million USD in Net Tangible Assets?',
    subTitle: () => <p>See OTC Market's definition of <a href={urls.GLOSSARY_NTA} target='_blank' rel='noopener noreferrer'>Net Tangible Assets</a>.</p>,
    result: {
      true: () => <>Yes, has at least $5 million USD in <a href={urls.GLOSSARY_NTA} target='_blank' rel='noopener noreferrer'>Net Tangible Assets</a>.</>,
      false: () => <>No, does not have at least $2 million USD in <a href={urls.GLOSSARY_NTA} target='_blank' rel='noopener noreferrer'>Net Tangible Assets</a>.</>
    },
    next: {
      OTCQX_US: {
        true: 'hasMinBidPriceOfTwentyFiveCents',
        false: 'hasBidPriceOfFiveDollars'
      },
      OTCQX_BANKS: {
        true: 'hasBidPriceOfOneDollar',
        false: 'hasBidPriceOfFiveDollars'
      },
      OTCQX_INTL: {
        true: 'hasMinBidPriceOfTwentyFiveCents',
        false: 'hasBidPriceOfFiveDollars'
      }
    }
  },
  {
    fieldName: 'hasBidPriceOfFiveDollars',
    title: 'Does the company have a bid price of at least $5 USD?',
    result: {
      true: 'Yes, has a bid price of at least $5 USD.',
      false: 'No, does not have a bid price of at least $5 USD.'
    },
    next: {
      OTCQX_US: {
        true: 'hasRequiredNtaRevenueNetIncomeTotalAssets',
        false: 'hasBidPahasFiledFormTwoElevenAtBidPriceOfFiveDollarsriceOfFiveDollars'
      },
      OTCQX_BANKS: {
        true: 'hasMarketCapOfTenMillion',
        false: 'hasBidPahasFiledFormTwoElevenAtBidPriceOfFiveDollarsriceOfFiveDollars'
      },
      OTCQX_INTL: {
        true: 'hasRequiredNtaRevenueNetIncomeTotalAssets',
        false: DOES_NOT_QUALIFY
      }
    }
  },
  {
    fieldName: 'hasRequiredNtaRevenueNetIncomeTotalAssets',
    title: () => <div>
      Does your company have one (1) of the following, as of the most recent Fiscal Year End:
      <div className={styles.list}>
        <div className={styles.column}>
          <div className={styles.item}>
            Net Tangible Assets:
          </div>
          <div className={styles.item}>
            Revenue:
          </div>
          <div className={styles.item}>
            Net Income:
          </div>
          <div className={styles.item}>
            Total Assets:
          </div>
        </div>
        <div>
          <div>
            $1 million USD
          </div>
          <div>
            $2 million USD
          </div>
          <div>
            $500 thousand USD
          </div>
          <div>
            $5 million USD
          </div>
        </div>
      </div>
    </div>,
    result: {
      true: 'Yes, has required NTA, Revenue, Net Income and Total Assets.',
      false: 'No, does not have required NTA, Revenue, Net Income and Total Assets.'
    },
    next: {
      OTCQX_US: {
        true: 'hasMarketCapOfTenMillion',
        false: DOES_NOT_QUALIFY
      },
      OTCQX_BANKS: {
        true: 'hasMarketCapOfTenMillion',
        false: DOES_NOT_QUALIFY
      },
      OTCQX_INTL: {
        true: 'hasGlobalMarketCapOfTenMillion',
        false: DOES_NOT_QUALIFY
      }
    }
  },
  {
    fieldName: 'hasBidPahasFiledFormTwoElevenAtBidPriceOfFiveDollarsriceOfFiveDollars',
    title: 'Has a recent Form 211 been filed with FINRA with a bid price of at least $5.00 USD?',
    result: {
      true: 'Yes, a Form 211 has been filed with FINRA with a $5 USD bid.',
      false: 'No, a Form 211 has not been filed with FINRA with a $5 USD bid.'
    },
    next: {
      OTCQX_US: {
        true: 'hasRequiredNtaRevenueNetIncomeTotalAssets',
        false: DOES_NOT_QUALIFY
      },
      OTCQX_BANKS: {
        true: 'hasRequiredNtaRevenueNetIncomeTotalAssets',
        false: DOES_NOT_QUALIFY
      }
    }
  },
  ...pennyStockQuestions,
  ...otcqxusQuestions,
  ...otcqxIntlQuestions
];

const DetermineFinancialEligibility = ({ section, appType, companyName, isIncomplete, isDelistFromMajorExchange, handleResult, handleUpdate }) => {
  const bidPriceValue = isDelistFromMajorExchange ? '$0.10' : '$0.25';
  const bidPriceQuestion = {
    fieldName: 'hasMinBidPriceOfTwentyFiveCents',
    title: `Does the company have a bid price of at least ${bidPriceValue} USD?`,
    result: {
      true: `Yes, has a bid price of at least ${bidPriceValue} USD.`,
      false: `No, does not have a bid price of at least ${bidPriceValue} USD.`
    },
    next: {
      OTCQX_US: {
        true: 'hasMarketCapOfTenMillion',
        false: DOES_NOT_QUALIFY
      },
      OTCQX_BANKS: {
        true: 'hasMarketCapOfTenMillion',
        false: DOES_NOT_QUALIFY
      },
      OTCQX_INTL: {
        true: 'hasGlobalMarketCapOfTenMillion',
        false: DOES_NOT_QUALIFY
      }
    }
  };

  return (
    <div>
      <p className={styles.description}>
        If you would like to view OTCQX financial standards and eligibilty requirements in detail, please check out our <ApplicationGuideLink appType={appType} />.
      </p>
      <QuestionFlow
        appType={appType}
        companyName={companyName}
        questions={[...questions, bidPriceQuestion]}
        handleResult={handleResult}
        handleUpdate={handleUpdate}
        isIncomplete={isIncomplete}
        section={section} />
    </div>
  );
};

DetermineFinancialEligibility.propTypes = {
  companyName: PropTypes.string,
  section: PropTypes.object,
  isDelistFromMajorExchange: PropTypes.bool,
  handleResult: PropTypes.func,
  isIncomplete: PropTypes.bool,
  handleUpdate: PropTypes.func
};

export default DetermineFinancialEligibility;
