import CompanyInformation from '../components/Application/CompanyInformation';
import EligibilityStandards from '../components/Application/EligibilityStandards';
import ChangeInControl from '../components/Application/ChangeInControl';
import ReportingStandards from '../components/Application/ReportingStandards';
import FinancialStandards from '../components/Application/FinancialStandards';
import OfficersDirectorsControlPersons from '../components/Application/OfficersDirectorsControlPersons';
import ServiceProviders from '../components/Application/ServiceProviders';
import SecurityDetails from '../components/Application/SecurityDetails';
import SecurityOwnership from '../components/Application/SecurityOwnership';
import LegalDisciplinaryHistory from '../components/Application/LegalDisciplinaryHistory';
import AdditionalServices from '../components/Application/AdditionalServices';
import DesignateContacts from '../components/Application/DesignateContacts';
import BackgroundCheckAuthorization from '../components/Application/BackgroundCheckAuthorization';
import SupplementalDocuments from '../components/Application/SupplementalDocuments';
import SignSubmit from '../components/Application/SignSubmit';
import PaymentSubmit from '../components/Application/PaymentSubmit';
import { routes } from '../constants';

export const applications = {
  OTCQX_US: {
    sections: [
      {
        name: 'Company Information',
        path: routes.COMPANY_INFO,
        apiField: 'companyInfo',
        component: CompanyInformation
      },
      {
        name: 'Eligibility Standards',
        path: routes.ELIGIBILITY_STANDARDS,
        apiField: 'eligibilityStandards',
        component: EligibilityStandards
      },
      {
        name: 'Reporting Standards',
        path: routes.REPORTING_STANDARDS,
        apiField: 'reportingStandards',
        component: ReportingStandards
      },
      {
        name: 'Financial Standards',
        path: routes.FINANCIAL_STANARDS,
        apiField: 'financialStandards',
        component: FinancialStandards
      },
      {
        name: 'Officers, Directors, & Control Persons',
        path: routes.OFFICIER_DIR_CONTROL_PERSON,
        tabs: ['officers', 'directors', 'control-person'],
        apiField: 'officerDirCtrlPersonList',
        component: OfficersDirectorsControlPersons
      },
      {
        name: 'Service Providers',
        path: routes.SERVICE_PROVIDERS,
        apiField: 'serviceProviders',
        component: ServiceProviders
      },
      {
        name: 'Security Details',
        path: routes.SECURITY_DETAILS,
        apiField: 'securityDetails',
        component: SecurityDetails
      },
      {
        name: 'Security Ownership',
        path: routes.SECURITY_OWNERSHIP,
        apiField: 'securityOwnership',
        component: SecurityOwnership
      },
      {
        name: 'Legal/Disciplinary History',
        path: routes.LEGAL_DIS_HISTORY,
        apiField: 'legalHistory',
        component: LegalDisciplinaryHistory
      },
      {
        name: 'Additional Services',
        path: routes.ADDT_SERVICES,
        apiField: 'additionalservices',
        component: AdditionalServices
      },
      {
        name: 'Designate Contacts',
        path: routes.DESIGNATE_CONTACTS,
        tabs: ['primary-contacts', 'authorized-users', 'billing-contacts'],
        apiField: 'designateContactsList',
        component: DesignateContacts
      },
      {
        name: 'Background Check Authorization',
        path: routes.BG_CHECK_AUTH,
        apiField: 'backgroundChecks',
        component: BackgroundCheckAuthorization
      },
      {
        name: 'Supplemental Documents',
        path: routes.SUPP_DOCS,
        component: SupplementalDocuments
      },
      {
        name: 'Review and Sign',
        path: routes.SIGN_SUBMIT,
        component: SignSubmit
      },
      {
        name: 'Pay and Submit',
        path: routes.PAYMENT_SUBMIT,
        component: PaymentSubmit
      }
    ]
  },
  OTCQX_BANKS: {
    sections: [
      {
        name: 'Company Information',
        path: routes.COMPANY_INFO,
        apiField: 'companyInfo',
        component: CompanyInformation
      },
      {
        name: 'Eligibility Standards',
        path: routes.ELIGIBILITY_STANDARDS,
        apiField: 'eligibilityStandards',
        component: EligibilityStandards
      },
      {
        name: 'Reporting Standards',
        path: routes.REPORTING_STANDARDS,
        apiField: 'reportingStandards',
        component: ReportingStandards
      },
      {
        name: 'Financial Standards',
        path: routes.FINANCIAL_STANARDS,
        apiField: 'financialStandards',
        component: FinancialStandards
      },
      {
        name: 'Officers, Directors, & Control Persons',
        path: routes.OFFICIER_DIR_CONTROL_PERSON,
        tabs: ['officers', 'directors', 'control-person'],
        apiField: 'officerDirCtrlPersonList',
        component: OfficersDirectorsControlPersons
      },
      {
        name: 'Service Providers',
        path: routes.SERVICE_PROVIDERS,
        apiField: 'serviceProviders',
        component: ServiceProviders
      },
      {
        name: 'Security Details',
        path: routes.SECURITY_DETAILS,
        apiField: 'securityDetails',
        component: SecurityDetails
      },
      {
        name: 'Security Ownership',
        path: routes.SECURITY_OWNERSHIP,
        apiField: 'securityOwnership',
        component: SecurityOwnership
      },
      {
        name: 'Legal/Disciplinary History',
        path: routes.LEGAL_DIS_HISTORY,
        apiField: 'legalHistory',
        component: LegalDisciplinaryHistory
      },
      {
        name: 'Additional Services',
        path: routes.ADDT_SERVICES,
        apiField: 'additionalservices',
        component: AdditionalServices
      },
      {
        name: 'Designate Contacts',
        path: routes.DESIGNATE_CONTACTS,
        tabs: ['primary-contacts', 'authorized-users', 'billing-contacts'],
        apiField: 'designateContactsList',
        component: DesignateContacts
      },
      {
        name: 'Background Check Authorization',
        path: routes.BG_CHECK_AUTH,
        apiField: 'backgroundChecks',
        component: BackgroundCheckAuthorization
      },
      {
        name: 'Supplemental Documents',
        path: routes.SUPP_DOCS,
        component: SupplementalDocuments
      },
      {
        name: 'Review and Sign',
        path: routes.SIGN_SUBMIT,
        component: SignSubmit
      },
      {
        name: 'Pay and Submit',
        path: routes.PAYMENT_SUBMIT,
        component: PaymentSubmit
      }
    ]
  },
  OTCQX_INTL: {
    sections: [
      {
        name: 'Company Information',
        path: routes.COMPANY_INFO,
        apiField: 'companyInfo',
        component: CompanyInformation
      },
      {
        name: 'Eligibility Standards',
        path: routes.ELIGIBILITY_STANDARDS,
        apiField: 'eligibilityStandards',
        component: EligibilityStandards
      },
      {
        name: 'Reporting Standards',
        path: routes.REPORTING_STANDARDS,
        apiField: 'reportingStandards',
        component: ReportingStandards
      },
      {
        name: 'Financial Standards',
        path: routes.FINANCIAL_STANARDS,
        apiField: 'financialStandards',
        component: FinancialStandards
      },
      {
        name: 'Officers, Directors, & Control Persons',
        path: routes.OFFICIER_DIR_CONTROL_PERSON,
        tabs: ['officers', 'directors', 'control-person'],
        apiField: 'officerDirCtrlPersonList',
        component: OfficersDirectorsControlPersons
      },
      {
        name: 'Service Providers',
        path: routes.SERVICE_PROVIDERS,
        apiField: 'serviceProviders',
        component: ServiceProviders
      },
      {
        name: 'Security Details',
        path: routes.SECURITY_DETAILS,
        apiField: 'securityDetails',
        component: SecurityDetails
      },
      {
        name: 'Security Ownership',
        path: routes.SECURITY_OWNERSHIP,
        apiField: 'securityOwnership',
        component: SecurityOwnership
      },
      {
        name: 'Legal/Disciplinary History',
        path: routes.LEGAL_DIS_HISTORY,
        apiField: 'legalHistory',
        component: LegalDisciplinaryHistory
      },
      {
        name: 'Additional Services',
        path: routes.ADDT_SERVICES,
        apiField: 'additionalservices',
        component: AdditionalServices
      },
      {
        name: 'Designate Contacts',
        path: routes.DESIGNATE_CONTACTS,
        tabs: ['primary-contacts', 'authorized-users', 'billing-contacts'],
        apiField: 'designateContactsList',
        component: DesignateContacts
      },
      {
        name: 'Background Check Authorization',
        path: routes.BG_CHECK_AUTH,
        apiField: 'backgroundChecks',
        component: BackgroundCheckAuthorization
      },
      {
        name: 'Supplemental Documents',
        path: routes.SUPP_DOCS,
        component: SupplementalDocuments
      },
      {
        name: 'Review and Sign',
        path: routes.SIGN_SUBMIT,
        component: SignSubmit
      },
      {
        name: 'Pay and Submit',
        path: routes.PAYMENT_SUBMIT,
        component: PaymentSubmit
      }
    ]
  },
  OTCQB: {
    sections: [
      {
        name: 'Company Information',
        path: routes.COMPANY_INFO,
        apiField: 'companyInfo',
        component: CompanyInformation
      },
      {
        name: 'Reporting Standards',
        path: routes.REPORTING_STANDARDS,
        apiField: 'reportingStandards',
        component: ReportingStandards
      },
      {
        name: 'Eligibility Standards',
        path: routes.ELIGIBILITY_STANDARDS,
        apiField: 'eligibilityStandards',
        component: EligibilityStandards
      },
      {
        name: 'Officers, Directors, & Control Persons',
        path: routes.OFFICIER_DIR_CONTROL_PERSON,
        tabs: ['officers', 'directors', 'control-person'],
        apiField: 'officerDirCtrlPersonList',
        component: OfficersDirectorsControlPersons
      },
      {
        name: 'Service Providers',
        path: routes.SERVICE_PROVIDERS,
        apiField: 'serviceProviders',
        component: ServiceProviders
      },
      {
        name: 'Security Details',
        path: routes.SECURITY_DETAILS,
        apiField: 'securityDetails',
        component: SecurityDetails
      },
      {
        name: 'Security Ownership',
        path: routes.SECURITY_OWNERSHIP,
        apiField: 'securityOwnership',
        component: SecurityOwnership
      },
      {
        name: 'Legal/Disciplinary History',
        path: routes.LEGAL_DIS_HISTORY,
        apiField: 'legalHistory',
        component: LegalDisciplinaryHistory
      },
      {
        name: 'Additional Services',
        path: routes.ADDT_SERVICES,
        apiField: 'additionalservices',
        component: AdditionalServices
      },
      {
        name: 'Designate Contacts',
        path: routes.DESIGNATE_CONTACTS,
        tabs: ['primary-contacts', 'authorized-users', 'billing-contacts'],
        apiField: 'designateContactsList',
        component: DesignateContacts
      },
      {
        name: 'Background Check Authorization',
        path: routes.BG_CHECK_AUTH,
        apiField: 'backgroundChecks',
        component: BackgroundCheckAuthorization
      },
      {
        name: 'Supplemental Documents',
        path: routes.SUPP_DOCS,
        component: SupplementalDocuments
      },
      {
        name: 'Review and Sign',
        path: routes.SIGN_SUBMIT,
        component: SignSubmit
      },
      {
        name: 'Pay and Submit',
        path: routes.PAYMENT_SUBMIT,
        component: PaymentSubmit
      }
    ]
  },
  DNS: {
    sections: [
      {
        name: 'Company Information',
        path: routes.COMPANY_INFO,
        apiField: 'companyInfo',
        component: CompanyInformation
      },
      {
        name: 'Reporting Standards',
        path: routes.REPORTING_STANDARDS,
        apiField: 'reportingStandards',
        component: ReportingStandards
      },
      {
        name: 'Change In Control',
        path: routes.CHANGE_IN_CONTROL,
        apiField: 'companyInfo',
        component: ChangeInControl
      },
      {
        name: 'Officers, Directors, & Control Persons',
        path: routes.OFFICIER_DIR_CONTROL_PERSON,
        tabs: ['officers', 'directors', 'control-person'],
        apiField: 'officerDirCtrlPersonList',
        component: OfficersDirectorsControlPersons
      },
      {
        name: 'Service Providers',
        path: routes.SERVICE_PROVIDERS,
        apiField: 'serviceProviders',
        component: ServiceProviders
      },
      {
        name: 'Security Details',
        path: routes.SECURITY_DETAILS,
        apiField: 'securityDetails',
        component: SecurityDetails
      },
      {
        name: 'Security Ownership',
        path: routes.SECURITY_OWNERSHIP,
        apiField: 'securityOwnership',
        component: SecurityOwnership
      },
      {
        name: 'Additional Services',
        path: routes.ADDT_SERVICES,
        apiField: 'additionalservices',
        component: AdditionalServices
      },
      {
        name: 'Designate Contacts',
        path: routes.DESIGNATE_CONTACTS,
        tabs: ['primary-contacts', 'authorized-users', 'billing-contacts'],
        apiField: 'designateContactsList',
        component: DesignateContacts
      },
      {
        name: 'Background Check Authorization',
        path: routes.BG_CHECK_AUTH,
        apiField: 'backgroundChecks',
        component: BackgroundCheckAuthorization
      },
      {
        name: 'Supplemental Documents',
        path: routes.SUPP_DOCS,
        component: SupplementalDocuments
      },
      {
        name: 'Review and Sign',
        path: routes.SIGN_SUBMIT,
        component: SignSubmit
      },
      {
        name: 'Pay and Submit',
        path: routes.PAYMENT_SUBMIT,
        component: PaymentSubmit
      }
    ]
  }
};
