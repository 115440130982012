import React from 'react';
import moment from 'moment-timezone';
import { appNames, types } from '../../constants';
import { View } from '@react-pdf/renderer';
import { Column, Label, Row, SectionHeader, Divider, SectionTitle, Value } from '../components';

const SuppDocsRequired = ({ type, docs, docTypes, shareHoldRequired, logoRequired, letterResignRequired, boardRequired, changeCtrlRequired, appApptCustDocRequired, crtOrdGrntCustRequired, draftQbCertRequired }) => {
  const isDNS = type === appNames.DNS;
  const shareHolderDoc = shareHoldRequired && docs ? docs.find(doc => doc.typeId === types.SHAREH_LIST) : {};
  const logoDoc = logoRequired && docs ? docs.find(doc => doc.typeId === types.LOGO) : {};
  const letterResignDoc = letterResignRequired && docs ? docs.find(doc => doc.typeId === types.LETTER_RESIGN) : {};
  const boardDoc = boardRequired && docs ? docs.find(doc => doc.typeId === types.BOARD) : {};
  const changeCtrlDoc = changeCtrlRequired && docs ? docs.find(doc => doc.typeId === types.DSC_CHG_CTRL_EVT) : {};
  const appApptCustDoc = appApptCustDocRequired && docs ? docs.find(doc => doc.typeId === types.APP_APPT_CUST) : {};
  const crtOrdGrntCustDoc = crtOrdGrntCustRequired && docs ? docs.find(doc => doc.typeId === types.CRT_ORD_GRNT_CUST) : {};
  const draftQbCertDoc = draftQbCertRequired && docs ? docs.find(doc => doc.typeId === types.DRAFT_QB_CERT) : {};
  const docTypesMap = docTypes && new Map(docTypes.map(doc => [doc.typeId, doc.description]));

  const LetterResign = () => <>
    <SectionTitle text={docTypesMap.get(types.LETTER_RESIGN)} />
    <Row>
      <Column size='50'>
        <Label text='UPLOAD DATE' />
        <Value text={letterResignDoc && letterResignDoc.attributes && moment(letterResignDoc.created).format('LL')} />
      </Column>
      <Column size='50'>
        <Label text='DOCUMENT DATE' />
        <Value text={letterResignDoc && letterResignDoc.attributes && moment(letterResignDoc.attributes.asOfDate).format('LL')} />
      </Column>
    </Row>
    <Row>
      <Column>
        <Label text='FULL NAME OF INDIVIDUAL LISTED ON DOCUMENT' />
        <Value text={letterResignDoc && letterResignDoc.attributes && letterResignDoc.attributes.fullName} />
      </Column>
    </Row>
    <Divider />
  </>;

  return (
    <>
      <View fixed>
        <SectionHeader text={`SUPPLEMENTAL DOCUMENTS${!isDNS ? ' | REQUIRED' : ''}`} />
      </View>
      <View>
        {shareHoldRequired && <>
          <SectionTitle text={docTypesMap.get(types.SHAREH_LIST)} />
          <Row>
            <Column size='50'>
              <Label text='UPLOAD DATE' />
              <Value text={shareHolderDoc && shareHolderDoc.attributes && moment(shareHolderDoc.created).format('LL')} />
            </Column>
            <Column size='50'>
              <Label text='DOCUMENT DATE' />
              <Value text={shareHolderDoc && shareHolderDoc.attributes && moment(shareHolderDoc.attributes.asOfDate).format('LL')} />
            </Column>
          </Row>
          <Divider />
        </>}
        {draftQbCertRequired && <>
          <SectionTitle text={docTypesMap.get(types.DRAFT_QB_CERT)} />
          <Row>
            <Column size='50'>
              <Label text='UPLOAD DATE' />
              <Value text={draftQbCertDoc && draftQbCertDoc.attributes && moment(draftQbCertDoc.created).format('LL')} />
            </Column>
            <Column size='50'>
              <Label text='DOCUMENT DATE' />
              <Value text={draftQbCertDoc && draftQbCertDoc.attributes && moment(draftQbCertDoc.attributes.asOfDate).format('LL')} />
            </Column>
          </Row>
          <Divider />
        </>}
        {changeCtrlRequired && <>
          <SectionTitle text={docTypesMap.get(types.DSC_CHG_CTRL_EVT)} />
          <Row>
            <Column size='50'>
              <Label text='UPLOAD DATE' />
              <Value text={changeCtrlDoc && changeCtrlDoc.attributes && moment(changeCtrlDoc.created).format('LL')} />
            </Column>
            <Column size='50'>
              <Label text='DOCUMENT DATE' />
              <Value text={changeCtrlDoc && changeCtrlDoc.attributes && moment(changeCtrlDoc.attributes.asOfDate).format('LL')} />
            </Column>
          </Row>
          <Divider />
        </>}
        {appApptCustDocRequired && <>
          <SectionTitle text={docTypesMap.get(types.APP_APPT_CUST)} />
          <Row>
            <Column size='50'>
              <Label text='UPLOAD DATE' />
              <Value text={appApptCustDoc && appApptCustDoc.attributes && moment(appApptCustDoc.created).format('LL')} />
            </Column>
            <Column size='50'>
              <Label text='DOCUMENT DATE' />
              <Value text={appApptCustDoc && appApptCustDoc.attributes && moment(appApptCustDoc.attributes.asOfDate).format('LL')} />
            </Column>
          </Row>
          <Divider />
        </>}
        {crtOrdGrntCustRequired && <>
          <SectionTitle text={docTypesMap.get(types.CRT_ORD_GRNT_CUST)} />
          <Row>
            <Column size='50'>
              <Label text='UPLOAD DATE' />
              <Value text={crtOrdGrntCustDoc && crtOrdGrntCustDoc.attributes && moment(crtOrdGrntCustDoc.created).format('LL')} />
            </Column>
            <Column size='50'>
              <Label text='DOCUMENT DATE' />
              <Value text={crtOrdGrntCustDoc && crtOrdGrntCustDoc.attributes && moment(crtOrdGrntCustDoc.attributes.asOfDate).format('LL')} />
            </Column>
          </Row>
          <Divider />
        </>}
        {(letterResignRequired && appApptCustDocRequired) && <LetterResign />}
        {boardRequired && <>
          <SectionTitle text={docTypesMap.get(types.BOARD)} />
          <Row>
            <Column size='50'>
              <Label text='UPLOAD DATE' />
              <Value text={boardDoc && boardDoc.attributes && moment(boardDoc.created).format('LL')} />
            </Column>
            <Column size='50'>
              <Label text='DOCUMENT DATE' />
              <Value text={boardDoc && boardDoc.attributes && moment(boardDoc.attributes.asOfDate).format('LL')} />
            </Column>
          </Row>
          <Divider />
        </>}
        {(letterResignRequired && !appApptCustDocRequired) && <LetterResign />}
        {logoRequired && <>
          <SectionTitle text={docTypesMap.get(types.LOGO)} />
          <Row>
            <Column>
              <Label text='UPLOAD DATE' />
              <Value text={logoDoc && logoDoc.attributes && moment(logoDoc.created).format('LL')} />
            </Column>
          </Row>
        </>}
      </View>
    </>
  );
};

export default SuppDocsRequired;
