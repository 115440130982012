import React from 'react';
import { View } from '@react-pdf/renderer';
import { Column, Label, Row, SectionHeader, Value } from '../components';

const FinancialStandards = ({ data }) => {
  let value;
  const doesNotQualify = data && data.doesNotQualify === true;
  const qualifiesForQXUS = data && data.qualifiesForQXUS === true;
  const qualifiesForQXUSPremier = data && data.qualifiesForQXUSPremier === true;
  const qualifiesForQXIntl = data && data.qualifiesForQXIntl === true;
  const qualifiesForQXIntlPremier = data && data.qualifiesForQXIntlPremier === true;

  if (doesNotQualify) value = 'Does Not Qualify for OTCQX';
  else if (qualifiesForQXUS) value = 'OTCQX U.S';
  else if (qualifiesForQXUSPremier) value = 'OTCQX U.S. Premier';
  else if (qualifiesForQXIntl) value = 'OTCQX International';
  else if (qualifiesForQXIntlPremier) value = 'OTCQX International Premier';

  return (
    <>
      <View fixed>
        <SectionHeader text='Financial Standards' />
      </View>
      <View>
        <Row>
          <Column size='100'>
            <Label text='STANDARD MET' />
            <Value text={value} />
          </Column>
        </Row>
      </View>
    </>
  );
};

export default FinancialStandards;
