import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { acceptFiles, urls } from '../../../constants';
import { Tooltip } from 'devextreme-react/tooltip';
import Entity from '../../Entity';
import DocumentTooltip from '../../DocumentTooltip';
import Title from '../../Title';
import Controls from '../../Controls';
import Label from '../../Label';
import ChooseFile from '../../ChooseFile';
import CheckBoxField from '../../CheckBoxField';
import DialogBox from '../../DialogBox';
import DateBox from '../../DateBox';
import styles from '../../Application/Application.module.scss';

const maxDate = new Date();

const PIFForm = ({ personView, pifFile, pifTitle, onMailTo, handleUpdate, onFileSubmit, setPIFView, setChildChange, company, errorMsg, isUploading, onRemoveClick, setSlidePane }) => {
  const [person, setPerson] = useState(personView);
  const personFullName = `${personView.firstName} ${personView.lastName}`;
  const [file, setFile] = useState(null);
  const [attributes, setAttributes] = useState({
    fullName: personFullName,
    company
  });
  const [submitText, setSubmitText] = useState(null);
  const [showIncomplete, setShowIncomplete] = useState(null);
  const [isEntityHover, setEntityHover] = useState(false);
  const [error, setError] = useState(null);
  const [emailDialog, setEmailDialog] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    setSubmitText('Add');
  }, []);

  useEffect(() => {
    setError(errorMsg);
  }, [errorMsg]);

  useEffect(() => {
    if (isUploading) setSubmitText('Adding Document...');
    if (!isUploading) setSubmitText('Add');
  }, [isUploading]);

  const renderEmailDialog = () => (
    <DialogBox
      title={`Request PIF from ${personFullName}`}
      cancelText='Cancel'
      submitText='Send'
      onCancelClick={() => setEmailDialog(false)}
      onSubmitClick={() => handleConfirmSendEmail()}>
      <p>Send an email to this person requesting they complete the Personal Information Form.</p>
      <p>Once you receive the completed form, please come back and select Add Personal Information Form above to upload.</p>
    </DialogBox>
  );

  const handleCancel = () => {
    setShowIncomplete(false);
    setSlidePane(false);
  };

  const handleSendEmailClick = () => {
    setEmailDialog(true);
  };

  const handleConfirmSendEmail = () => {
    onMailTo(personView);
    setEmailDialog(false);
  };

  const handleSubmit = () => {
    if (file) {
      const data = { ...attributes };
      if (!attributes.asOfDate) {
        setShowIncomplete(true);
        return;
      };
      onFileSubmit(file, data);
    } else {
      handleUpdate(person, true);
    }
  };

  const onDateChange = value => {
    setAttributes(prevState => {
      return {
        ...prevState,
        asOfDate: value
      };
    });
  };

  const onValueChanged = (value, field) => {
    setPerson(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });
  };

  const handleRemove = (e, item) => {
    e.stopPropagation();
    onRemoveClick(item);
  };

  const renderEntity = (item) => {
    const id = `pif-${item.id}`;

    return (
      <div
        id={id}
        onMouseEnter={() => setEntityHover(id)}
        onMouseLeave={() => setEntityHover(null)}>
        <Entity
          title={`PIF (${pifFile.attributes.fullName})`}
          subTitle='Complete'
          isComplete
          icon='fileComplete'
          handleRemove={e => handleRemove(e, item)} />
        <Tooltip
          target={`#${id}`}
          visible={isEntityHover && isEntityHover === id}
          position='bottom'
          hideOnOutsideClick={false}
        >
          <div>
            <DocumentTooltip title={pifTitle} attributes={item.attributes} />
          </div>
        </Tooltip>
      </div>
    );
  };

  return (
    <div>
      <Title title='Personal Information Form' type='h2' />
      <p>
        {`Please upload a Personal Information Form (PIF) for ${personFullName}.`}
        <br />
        <a href={urls.PIF_URL} target='_blank' rel='noopener noreferrer'>See Personal Information Form Template.</a>
      </p>
      {!pifFile && <div>
        <div className='mtLg mbLg'>
          <ChooseFile file={file && file} accept={acceptFiles.DOCUMENTS} accepList={acceptFiles.DOCS_LIST} setFile={setFile} isInactive={person.noPIFAvailable} setError={setError} />
        </div>
        <p>
          Enter Date of document
        </p>
        <DateBox
          className={styles.date}
          showClearButton
          placeholder='MM/DD/YYYY'
          type='date'
          max={maxDate}
          disabled={person.noPIFAvailable}
          isRequired={showIncomplete}
          errorText='Incomplete Data. Please enter date of document.'
          value={attributes.asOfDate}
          onValueChanged={e => onDateChange(e.value)}
        />
      </div>}
      {pifFile && renderEntity(pifFile)}
      <CheckBoxField
        className='mtLg'
        value={person.noPIFAvailable}
        text='Check here if you do not have the Personal Information Form available for this individual at this time. Note that the form is required to process this application. You will be able to upload it after submission of this application through the Supplemental Documents section.'
        disabled={!!(pifFile || file)}
        onValueChanged={e => onValueChanged(e.value, 'noPIFAvailable')} />
      <div className='mtLg'>
        <Label
          title={`Need to send a PIF to ${personFullName}?`}
          hasButton={!emailSent}
          buttonText='Send Email w/ PIF'
          hasComplete={emailSent}
          completeText='PIF Sent'
          onButtonClick={handleSendEmailClick} />
        {emailDialog && renderEmailDialog()}
      </div>
      {error && <Label className='mtLg' isError title={error} />}
      <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText={submitText}
          isLoading={isUploading}
          onCancelClick={handleCancel}
          onSubmitClick={handleSubmit} />
      </div>
    </div>
  );
};

PIFForm.propTypes = {
  person: PropTypes.object,
  handleUpdate: PropTypes.func,
  setSlidePane: PropTypes.func,
  setPIFView: PropTypes.func,
  company: PropTypes.string,
  pifFile: PropTypes.object,
  pifTitle: PropTypes.string,
  onRemoveClick: PropTypes.func
};

export default PIFForm;
