import React from 'react';
import { View } from '@react-pdf/renderer';
import { Column, Label, Row, SectionHeader, Value } from '../components';
import { format } from '../../utils/locale';

const AdditionalServices = ({ data, hasVIC, hasBluesky, hasLevel2 }) => (
  <>
    <View fixed>
      <SectionHeader text='ADDITIONAL SERVICES' />
    </View>
    <View>
      <Row>
        {hasBluesky && <Column size='50'>
          <Label text='Blue Sky Monitoring Service' />
          <Value text={data && format(data.isInterestedInBSM, null, 'bool')} />
        </Column>}
        {hasVIC && <Column size='50'>
          <Label text='VIRTUAL INVESTOR CONFERENCE' />
          <Value text={data && format(data.isInterestedInVIC, null, 'bool')} />
        </Column>}
        {hasLevel2 && <Column size='75'>
          <Label text='Level 2' />
          <Value text={data && format(data.isRealTimeLevel2, null, 'bool')} />
        </Column>}
      </Row>
    </View>
  </>
);

export default AdditionalServices;
