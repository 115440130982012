import React, { useState } from 'react';
import { Tooltip } from 'devextreme-react/tooltip';
import cn from 'classnames';
import { isSameOrBefore } from '../../../utils/helper';
import TextBox from '../../TextBox';
import SelectBox from '../../SelectBox';
import NumberBox from '../../NumberBox';
import DateBox from '../../DateBox';
import CheckBoxField from '../../CheckBoxField';
import Title from '../../Title';
import Footnote from '../../Footnote';
import Controls from '../../Controls';
import FieldError from '../../FieldError';
import { secTypes, secClasses } from '../../../constants/securityDetails';
import { format } from '../../../utils/locale';
import { errors, urls, reportingStandards } from '../../../constants';
import { isNumber, isCusip, isIsin } from '../../../utils/validations';
import styles from '../Application.module.scss';
import secStyles from './SecurityDetails.module.scss';

const maxDate = new Date();
const incompleteError = errors.INCOMPLETE_DATA_FIELD;
const publicFloatTitleId = 'publicFloatTitle';
const publicFloatTooltipText = 'For U.S. and Canadian companies, to assist with calculating pubic float, your Transfer Agent may be able to provide a shareholder report that identifies the number of shares that are ‘restricted’ vs. ‘unrestricted’.  Please note that although securities are eligible to be sold pursuant to a resale S-1 or Rule 144 holding period,  your Transfer Agent may require additional documentation such as an opinion letter to remove any pre-existing legend.';

const secDetailObj = {
  isQXtraded: false,
  isQBtraded: false,
  type: '',
  securityClass: '',
  cusip: '',
  authorizedShares: '',
  isAuthorizedUnlimited: false,
  restrictedShares: '',
  unrestrictedShares: '',
  sharesOutstanding: '',
  publicFloat: '',
  publicFloatPercentage: '',
  sharesAsOfDate: '',
  beneficialShareholders: '',
  ownerships: []
};

const AddSecurityDetail = ({ securityView, setSlidePane, handleAdd, handleUpdate, readOnly, tradedField, isTradedText, showIncompletes, reportingStandard, isDNS, financialStandards }) => {
  const [secDetail, setSecDetail] = useState(securityView || { ...secDetailObj, [tradedField]: true });
  const [showPublicFloatToolTip, setShowPublicFloatToolTip] = useState(false);
  const [authorizedSharesFocus, setAuthorizedSharesFocus] = useState(false);
  const [sharesAsOfDateFocus, setSharesAsOfDateFocus] = useState(false);
  const [sharesOutstandingFocus, setSharesOutstandingFocus] = useState(false);
  const [restrictedSharesFocus, setRestrictedSharesFocus] = useState(false);
  const [unrestrictedSharesFocus, setUnrestrictedSharesFocus] = useState(false);
  const [beneficialShareholdersFocus, setBeneficialShareholdersFocus] = useState(false);
  const [unrestrictedSharesAffiliatesFocus, setUnrestrictedSharesAffiliatesFocus] = useState(false);
  const [parValueFocus, setParValueFocus] = useState(false);
  const isAdd = !securityView;
  const hasSharesOutstandingValue = isNumber(secDetail.sharesOutstanding);
  const hasRestrictedSharesValue = isNumber(secDetail.restrictedShares);
  const hasUnrestrictedSharesValue = isNumber(secDetail.unrestrictedShares);
  const isSharesOutstandingValid = secDetail.isAuthorizedUnlimited || secDetail.sharesOutstanding <= secDetail.authorizedShares;
  const isRestrictedSharesValid = secDetail.restrictedShares <= secDetail.sharesOutstanding;
  const isUnrestrictedSharesValid = (secDetail.unrestrictedShares + secDetail.restrictedShares) === secDetail.sharesOutstanding;
  const isUnrestrictedSharesAffiliatesValid = secDetail.unrestrictedSharesAffiliates <= secDetail.unrestrictedShares;
  const setValidUnrestrictedShares = secDetail.unrestrictedShares ? isUnrestrictedSharesValid : true;
  const setValidUnresSharesAffilates = secDetail.unrestrictedSharesAffiliates ? isNumber(secDetail.unrestrictedSharesAffiliates) : true;
  const publicValid = hasSharesOutstandingValue && hasRestrictedSharesValue && hasUnrestrictedSharesValue && isSharesOutstandingValid && isRestrictedSharesValid && isUnrestrictedSharesValid;
  const publicFloat = secDetail.sharesOutstanding - secDetail.restrictedShares - secDetail.unrestrictedSharesAffiliates;
  const publicFloatPercentage = (publicFloat / secDetail.sharesOutstanding) * 100;
  const isDateSameOrBefore = isSameOrBefore(secDetail.sharesAsOfDate);
  const isIntlReportStnd = reportingStandard === reportingStandards.INTERNATIONAL;
  const showParValueFieldError = showIncompletes && !isNumber(secDetail.parValue);
  const qualifiesForQXIntlPremier = financialStandards && financialStandards.qualifiesForQXIntlPremier === true;

  const onValueChanged = (value, field) => {
    setSecDetail(prevState => {
      return {
        ...prevState,
        [field]: value
      };
    });

    if (field === 'isAuthorizedUnlimited' && value === true) {
      setSecDetail(prevState => {
        return {
          ...prevState,
          authorizedShares: null
        };
      });
    }
  };

  const customFormatter = {
    formatter: (value) => {
      // Format the number as percentage with 2 decimal places
      if (!publicValid || !isNumber(value)) return 'N/A';

      return `${parseFloat(value).toFixed(2)}%`;
    }
  };

  const handleUpdateDetail = () => {
    handleUpdate({ ...secDetail });
  };

  const handleSubmit = () => {
    handleAdd({ ...secDetail });
  };

  const renderPublicFloatTitle = () => {
    return <a id={publicFloatTitleId} onMouseEnter={() => setShowPublicFloatToolTip(true)} onMouseLeave={() => setShowPublicFloatToolTip(false)}>Public Float</a>;
  };

  return (
    <div>
      <p className={styles.description}>
        Provide information for the security.
      </p>
      {!isDNS && <CheckBoxField
        text={isTradedText}
        readOnly={readOnly}
        value={secDetail[tradedField]}
        onValueChanged={e => onValueChanged(e.value, tradedField)} />}
      {!secDetail.isExistingSecurity && <div className={cn(styles.innerGrid, 'mtMed')}>
        <div className={styles.col3}>
          <SelectBox
            label='Security Type'
            name='secType'
            placeholder='Select Type'
            isRequired={showIncompletes}
            items={secTypes}
            tabIndex={1}
            readOnly={readOnly}
            value={secDetail.type}
            onValueChanged={e => onValueChanged(e.value, 'type')} />
        </div>
        <div className={styles.col3}>
          <SelectBox
            label='Security Class'
            name='secClass'
            placeholder='Select Class'
            isRequired={showIncompletes}
            valueExpr='id'
            displayExpr='name'
            tabIndex={2}
            items={secClasses}
            readOnly={readOnly}
            value={secDetail.securityClass}
            onValueChanged={e => onValueChanged(e.value, 'securityClass')} />
        </div>
        <div className={styles.col3}>
          <TextBox
            label='CUSIP Number'
            placeholder='XXXXXXXXX'
            name='cusip'
            tabIndex={3}
            maxLength={9}
            isRequired={showIncompletes && !isIntlReportStnd}
            isValid={secDetail.cusip ? isCusip(secDetail.cusip) : true}
            errorText={secDetail.cusip ? errors.CUSIP : null}
            readOnly={readOnly}
            value={secDetail.cusip}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'cusip')} />
        </div>
        {isIntlReportStnd && <div className={styles.col3}>
          <TextBox
            label='ISIN'
            placeholder='XX-XXXXXXXXX-X'
            name='isin'
            tabIndex={5}
            maxLength={14}
            isValid={secDetail.isin ? isIsin(secDetail.isin) : true}
            errorText={secDetail.isin ? errors.ISIN : null}
            readOnly={readOnly}
            value={secDetail.isin}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'isin')} />
        </div>}
        <div className={styles.col3}>
          <NumberBox
            label='Par Value'
            placeholder='$XXX'
            name='parValue'
            hideErrorIcon
            tabIndex={4}
            format='#0.#####'
            readOnly={readOnly}
            value={secDetail.parValue}
            isRequired={showIncompletes}
            showClearButton
            onValueChanged={e => onValueChanged(e.value, 'parValue')} />
          <Footnote text={() => <>Enter “0” if no par value</>} />
        </div>
      </div>}
      <Title className='mtXL mbXL' title='Share Information' type='h2' />
      <div className={styles.innerGrid}>
        <div className={styles.col4}>
          <Title className={secStyles.title} title='The data in this section is as of:' type='h3' />
          {(!isDateSameOrBefore || (showIncompletes && !secDetail.sharesAsOfDate)) && <FieldError error={!isDateSameOrBefore ? errors.FUTURE_DATE : incompleteError} isFocus={sharesAsOfDateFocus} />}
        </div>
        <div className={styles.col2}>
          <DateBox
            className={'maxHeight'}
            showClearButton
            placeholder='MM/DD/YYYY'
            tabIndex={4}
            readOnly={readOnly}
            value={secDetail.sharesAsOfDate}
            type='date'
            hideErrorIcon
            onFocusIn={() => setSharesAsOfDateFocus(true)}
            onFocusOut={() => setSharesAsOfDateFocus(false)}
            isRequired={showIncompletes}
            max={maxDate}
            onValueChanged={e => onValueChanged(e.value, 'sharesAsOfDate')} />
        </div>
        <div className={styles.col4}>
          <Title className={secStyles.title} title='Shares Authorized' type='h3' />
          {(showIncompletes && !secDetail.isAuthorizedUnlimited) && !isNumber(secDetail && secDetail.authorizedShares) && <FieldError error={incompleteError} isFocus={authorizedSharesFocus} />}
        </div>
        <div className={styles.col2}>
          <NumberBox
            className={'maxHeight'}
            placeholder={secDetail.isAuthorizedUnlimited ? 'N/A' : 'XXX,XXX'}
            name='authorizedShares'
            tabIndex={5}
            format='#,##0'
            disabled={secDetail.isAuthorizedUnlimited}
            hideErrorIcon
            isRequired={showIncompletes && !secDetail.isAuthorizedUnlimited}
            readOnly={readOnly}
            value={!secDetail.isAuthorizedUnlimited ? secDetail.authorizedShares : undefined}
            showClearButton
            onFocusIn={() => setAuthorizedSharesFocus(true)}
            onFocusOut={() => setAuthorizedSharesFocus(false)}
            onValueChanged={e => onValueChanged(e.value, 'authorizedShares')} />
        </div>
        <div className={styles.col4}>
          <div className={secStyles.subTitle}>Unlimited Number of Shares Authorized</div>
        </div>
        <CheckBoxField
          className={styles.col2}
          size='small'
          readOnly={readOnly}
          value={secDetail.isAuthorizedUnlimited}
          onValueChanged={e => onValueChanged(e.value, 'isAuthorizedUnlimited')} />
        <div className={styles.col4}>
          <Title title='Total Shares Outstanding' type='h3' className={secStyles.title} />
          {(showIncompletes && !secDetail.sharesOutstanding) && <FieldError error={incompleteError} isFocus={sharesOutstandingFocus} />}
          {(secDetail.sharesOutstanding && !isSharesOutstandingValid) ? <FieldError error='Shares Outstanding cannot be greater than Number of Shares Authorized.' isFocus={sharesOutstandingFocus} /> : ''}
        </div>
        <div className={styles.col2}>
          <NumberBox
            className='maxHeight'
            placeholder='XXX,XXX'
            format='#,##0'
            tabIndex={6}
            hideErrorIcon
            isRequired={showIncompletes}
            isValid={secDetail.sharesOutstanding ? isSharesOutstandingValid : true}
            name='sharesOutstanding'
            readOnly={readOnly}
            value={secDetail.sharesOutstanding}
            showClearButton
            onFocusIn={() => setSharesOutstandingFocus(true)}
            onFocusOut={() => setSharesOutstandingFocus(false)}
            onValueChanged={e => onValueChanged(e.value, 'sharesOutstanding')} />
        </div>
        {!isDNS && <>
          <div className={styles.col4}>
            <Title className={secStyles.title} title='Restricted Shares' type='h3' />
            {(showIncompletes && !secDetail.restrictedShares) && <FieldError error={incompleteError} isFocus={restrictedSharesFocus} />}
            {(secDetail.restrictedShares && !isRestrictedSharesValid) ? <FieldError error='Number of Restricted Shares cannot be greater than the Number of Shares Outstanding.' isFocus={restrictedSharesFocus} /> : ''}
          </div>

          <div className={styles.col2}>
            <NumberBox
              className='maxHeight'
              placeholder='XXX,XXX'
              format='#,##0'
              tabIndex={7}
              hideErrorIcon
              isRequired={showIncompletes}
              isValid={secDetail.restrictedShares ? isRestrictedSharesValid : true}
              name='restrictedShares'
              readOnly={readOnly}
              value={secDetail.restrictedShares}
              showClearButton
              onFocusIn={() => setRestrictedSharesFocus(true)}
              onFocusOut={() => setRestrictedSharesFocus(false)}
              onValueChanged={e => onValueChanged(e.value, 'restrictedShares')} />
          </div>
        </>}
        {!isDNS && <>
          <div className={styles.col4}>
            <Title className={secStyles.title} title='Unrestricted Shares' type='h3' />
            {(showIncompletes && !secDetail.unrestrictedShares) && <FieldError error={incompleteError} isFocus={unrestrictedSharesFocus} />}
            {(secDetail.unrestrictedShares && !isUnrestrictedSharesValid) ? <FieldError error='Restricted Shares plus Unrestricted Shares should equal Total Shares Outstanding.' isFocus={unrestrictedSharesFocus} /> : ''}
          </div>
          <div className={styles.col2}>
            <NumberBox
              className='maxHeight'
              placeholder='XXX,XXX'
              format='#,##0'
              tabIndex={8}
              hideErrorIcon
              isRequired={showIncompletes}
              isValid={setValidUnrestrictedShares}
              name='unrestrictedShares'
              readOnly={readOnly}
              value={secDetail.unrestrictedShares}
              showClearButton
              onFocusIn={() => setUnrestrictedSharesFocus(true)}
              onFocusOut={() => setUnrestrictedSharesFocus(false)}
              onValueChanged={e => onValueChanged(e.value, 'unrestrictedShares')} />
          </div>
        </>}
        {!isDNS && <>
          <div className={styles.col4}>
            <Title className={secStyles.wideTitle} title='Unrestricted Shares Held by Officers, Directors, 10% Holders & Affiliates' type='h3' />
            {(showIncompletes && !isNumber(secDetail.unrestrictedSharesAffiliates && secDetail.unrestrictedSharesAffiliates)) && <FieldError className={secStyles.alignTop} error={incompleteError} isFocus={unrestrictedSharesAffiliatesFocus} />}
            {secDetail.unrestrictedSharesAffiliates && !isUnrestrictedSharesAffiliatesValid ? <FieldError className={secStyles.alignTop} error='Unrestricted Shares should be greater than Unrestricted Shares Held by Officers, Directors, 10% Holders & Affiliates.' isFocus={unrestrictedSharesAffiliatesFocus} /> : ''}
          </div>
          <div className={styles.col2}>
            <NumberBox
              className='maxHeight'
              placeholder='XXX,XXX'
              format='#,##0'
              tabIndex={9}
              isRequired={showIncompletes}
              isValid={setValidUnresSharesAffilates}
              name='unrestrictedShares'
              hideErrorIcon
              readOnly={readOnly}
              value={secDetail.unrestrictedSharesAffiliates}
              showClearButton
              onFocusIn={() => setUnrestrictedSharesAffiliatesFocus(true)}
              onFocusOut={() => setUnrestrictedSharesAffiliatesFocus(false)}
              onValueChanged={e => onValueChanged(e.value, 'unrestrictedSharesAffiliates')} />
          </div>
        </>}
        {!isDNS && <>
          <div className={styles.col4}>
            <div className={secStyles.wideTitle}>
              <Title title='Number of Beneficial Shareholders of at least 100 shares' type='h3' noMargin />
              <Footnote text={() => <>Beneficial Shareholder means any person who, directly or indirectly has or shares voting power of such security or investment power, which includes the power to dispose, or to direct the disposition of, such security.<br /><a href={urls.GLOSSARY_BENEFICIAL_SHAREHOLDER} target='_blank' rel='noopener noreferrer'>See Beneficial Shareholders definition</a></>} />
            </div>
            {(showIncompletes && !qualifiesForQXIntlPremier && !secDetail.beneficialShareholders) && <FieldError className={secStyles.alignTop} error={incompleteError} isFocus={beneficialShareholdersFocus} />}
          </div>
          <NumberBox
            className={cn(styles.col2, 'maxHeight')}
            placeholder='XXX'
            format='#,##0'
            name='beneficialShareholders'
            readOnly={readOnly}
            value={secDetail.beneficialShareholders}
            showClearButton
            tabIndex={10}
            hideErrorIcon
            isRequired={showIncompletes && !qualifiesForQXIntlPremier}
            onFocusIn={() => setBeneficialShareholdersFocus(true)}
            onFocusOut={() => setBeneficialShareholdersFocus(false)}
            onValueChanged={e => onValueChanged(e.value, 'beneficialShareholders')} />
        </>}
        {(secDetail.isExistingSecurity || (secDetail.isExistingSecurity && isDNS)) && <>
          <div className={styles.col4}>
            <div className={secStyles.wideTitle}>
              <Title title='Par Value' type='h3' noMargin />
              <Footnote text={() => <>Enter “0” if no par value<br /></>} />
            </div>
            {showParValueFieldError && <FieldError error={incompleteError} isFocus={parValueFocus} />}

          </div>
          <div className={styles.col2}>
            <NumberBox
              className='maxHeight'
              placeholder='$XXX'
              name='parValue'
              format='#0.#####'
              hideErrorIcon
              tabIndex={11}
              readOnly={readOnly}
              value={secDetail.parValue}
              isRequired={showIncompletes}
              onFocusIn={() => setParValueFocus(true)}
              onFocusOut={() => setParValueFocus(false)}
              showClearButton
              onValueChanged={e => onValueChanged(e.value, 'parValue')} />
          </div>
        </>}
      </div>
      {!isDNS && <>
        <Title className='mtXL mbXL' title='Public Float Calculated from Share Information' type='h2' />
        <div className={styles.innerGrid}>
          <div className={styles.col4}>
            <Title title={renderPublicFloatTitle()} type='h3' noMargin />
            <Footnote text={() => <>Total Shares Outstanding less any Restricted Shares and any Unrestricted Shares Held by Officers, Directors, 10% Holders & Affiliates<br /><a href={urls.GLOSSARY_PUBLIC_FLOAT} target='_blank' rel='noopener noreferrer'>See Public Float definition</a></>} />
            <Tooltip
              maxWidth={600}
              target={`#${publicFloatTitleId}`}
              visible={showPublicFloatToolTip}
              position='bottom'
              hideOnOutsideClick={false}>
              <div>
                {publicFloatTooltipText}
              </div>
            </Tooltip>
          </div>
          <div className={styles.col2}>
            <NumberBox
              className='maxHeight'
              placeholder='N/A'
              format='#,##0'
              name='publicFloat'
              disabled
              readOnly={readOnly}
              value={publicFloat}
              onValueChanged={e => onValueChanged(e.value, 'publicFloat')}
            />
          </div>
          <div className={styles.col4}>
            <Title title='Public Float %' type='h3' noMargin />
            <Footnote text={() => <>Public Float as a percentage of Total Shares Outstanding<br /><a href={urls.GLOSSARY_PUBLIC_FLOAT} target='_blank' rel='noopener noreferrer'>See Public Float definition</a></>} />
          </div>
          <div className={styles.col2}>
            <NumberBox
              className='maxHeight'
              placeholder='N/A'
              name='publicFloatPercentage'
              disabled
              readOnly={readOnly}
              format={customFormatter}
              value={publicFloatPercentage}
              onValueChanged={e => onValueChanged(e.value, 'publicFloatPercentage')}
            />
          </div>
        </div>
      </>}
      {!readOnly && <div className={cn('mtXXL', styles.controls)}>
        <Controls
          cancelText='Cancel'
          submitText='Save'
          onCancelClick={() => setSlidePane(false)}
          onSubmitClick={isAdd ? handleSubmit : handleUpdateDetail} />
      </div>}
    </div>
  );
};

export default AddSecurityDetail;
