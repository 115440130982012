import React from 'react';
import Button from '../Button';
import PropTypes from 'prop-types';
import { urls } from '../../constants';
import ApplicationTitle from '../ApplicationTitle';
import styles from './StartApplicationDisclaimer.module.scss';

const StartApplicationDisclaimer = ({ application, handleStartApp }) => {
  const isQX = [1, 2, 3].includes(application.typeId);
  const isOTCQXBanks = application.typeId === 2;
  const isQB = application.typeId === 4;
  const isDNS = application.typeId === 5;
  const pifLink = <a href={urls.PIF_URL} target='_blank' rel='noopener noreferrer'>Personal Information Forms</a>;
  const pifText = 'may be required for certain Officers, Directors and Control Persons upon request';

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.appTitle}>
          <ApplicationTitle type={application.typeDesc} company={application.companyInfo.name} />
        </div>
        <h1>
          Before you start the {application.typeDesc} Application
        </h1>
        <p>
          You will need to collect certain information from your team. Some of the items that will be required are listed below.
        </p>
        <ul>
          <li>
            Recent shareholder list issued by your transfer agent. During the review process, OTC Markets may also ask for a NOBO list
          </li>
          <li>
            Share ownership information for all Officers, Directors and Control Persons (beneficial owners of 5% or more) of any class of the issuer’s securities
          </li>

          {isDNS && <>
            <li>
              Date of birth and home address (City, State and Country only) for all Officers, Directors, Control Persons and Authorized OTCIQ Users for background check purposes. Not required for international companies listed on a Qualified Foreign Exchange
            </li>
            <li>
              Personal Information Forms may be required for certain Officers, Directors, Control Persons, Primary Contacts, Authorized OTCIQ Users and Primary Billing Contacts who reside outside the U.S. or Canada
            </li>
          </>
          }
          {isQX && <>
            <li>
              Date of birth and home address (City, State and Country only) for all Officers, Directors and Control Persons for background check purposes
            </li>
            <li>
              {pifLink} {pifText}
            </li>
            {!isOTCQXBanks && <li>Company’s corporate logo</li>}
            {isOTCQXBanks && <li>Bank’s corporate logo</li>}
          </>
          }
          {isQB && <>
            <li>
              Date of birth and home address (City, State and Country only) for all Officers, Directors, Control Persons and Authorized OTCIQ Users for background check purposes
            </li>
            <li>
              {pifLink} {pifText}
            </li>
            <li>An OTCQB Certification is required from each company prior to approval of an Application. We recommend that you submit a draft of the  <a href={urls.OTCQB_CERT} target='_blank' rel='noopener noreferrer'>OTCQB Certification</a> as a Supplemental Document along with this Application to avoid delays in processing</li>
          </>
          }
        </ul>
        <h1>
          Payment Information
        </h1>
        <ul>
          <li>Application Fee payment is required prior to submission of this application</li>
          <li>See the Corporate Services <a href={urls.FEE_SCHEDULE} target='_blank' rel='noopener noreferrer'>Fee Schedule</a> for Application and Annual Fee information</li>
        </ul>
        <h1>
          Information for Publicly Traded Companies
        </h1>
        <p>
          If you are a publicly traded company, your application will be pre-populated with the information that is currently in our systems.  You will be able to make necessary corrections and adjustments throughout the process.
        </p>
        {isQX && <>
          <h1>
            Information for Companies seeking an Initial Review by OTC Markets Group
          </h1>
          <p>Companies that do not have proprietary priced quotations may become quoted by undergoing an “Initial Review” by OTC Markets Group or by having a broker-dealer file and clear a Form 211 with FINRA.</p>
          <p><b>Note that an Initial Review may extend the processing time of the company's OTCQX application. Additionally, the company must arrange for a broker-dealer to begin quoting the security on OTC Link within 4 days of approval for trading on OTCQX.</b></p>
          <p>Companies that may require an initial review include private companies looking to go public, non-U.S. companies listed on a foreign stock exchange and companies with securities on the Grey Market. OTC Markets Group may conduct the initial reviews as part of the OTCQX application and approval process for qualifying companies.</p>
        </> }
        {isQB && <> <h1>
          Information for Companies seeking an Initial Review by OTC Markets Group
        </h1>
          <p>Companies that do not have proprietary priced quotations may become quoted by undergoing an “Initial Review” by OTC Markets Group or by having a broker-dealer file and clear a Form 211 with FINRA.</p>
          <p><b>Note that an Initial Review may extend the processing time of the company's OTCQB application. Additionally, the company must arrange for a broker-dealer to begin quoting the security on OTC Link within 4 days of approval for trading on OTCQB.</b></p>
          <p>Companies that may require an initial review include private companies looking to go public, non-U.S. companies listed on a foreign stock exchange, companies that were previously delinquent in their reporting obligations and companies with securities on the Grey Market. OTC Markets Group may conduct the initial reviews as part of the OTCQB application and approval process for qualifying companies. Shell Companies (or companies that display characteristics common to Shell Companies) and companies with less than 10% public float applying to the OTCQB Market should work directly with a broker-dealer to submit a Form 211 with FINRA.</p>
        </> }
        <h1>
          Saving Progress
        </h1>
        <p>
          You can save your work and resume later, just click the Save button on the top right corner of your screen before you leave the application.   Your progress will automatically be saved as you move between sections of the application form.
        </p>
        <h1>
          Information Privacy
        </h1>
        <p>
          All personal information is collected in accordance with our <a href={urls.PRIVACY_POLICY} target='_blank'>Privacy Policy.</a>  Please review for further details.
        </p>
        <Button className='mtLg' title='Start Application' color='gray' fullWidth onClick={() => handleStartApp()} />
      </div>
    </div>
  );
};

StartApplicationDisclaimer.propTypes = {
  application: PropTypes.object,
  handleStartApp: PropTypes.func
};

export default StartApplicationDisclaimer;
