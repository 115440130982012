import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Tooltip } from 'devextreme-react/tooltip';
import { isBoolean } from '../../../utils/validations';
import { appStates, entities, errors, types, reportingStandards } from '../../../constants';
import { NotificationsContext } from '../../../context/Notifications.context';
import { ApplicationContext } from '../../../context/Application.context';
import { TypesContext } from '../../../context/Types.context';
import { AuthContext } from '../../../context/Auth.context';
import { deleteApplicationDoc, uploadApplicationDoc } from '../../../api/document';
import Entity from '../../Entity';
import Title from '../../Title';
import Label from '../../Label';
import SlidePanel from '../../SlidePanel';
import DocumentTooltip from '../../DocumentTooltip';
import AddNewDocument from '../../AddNewDocument';
import FieldError from '../../FieldError';
import ApplicationDescription from '../../ApplicationDescription';
import styles from '../Application.module.scss';
import { supplementalDocumentsCheckComplete } from '../../../constants/cardComplete';

const SupplementalDocuments = ({ name, saveApplication }) => {
  const [authState] = useContext(AuthContext);
  const [state, dispatch] = useContext(ApplicationContext);
  const [notificationState, dispatchNotification] = useContext(NotificationsContext);
  const [typesState] = useContext(TypesContext);
  const [listFocus, setListFocus] = useState(false);
  const [error, setError] = useState(null);
  const [isEntityHover, setEntityHover] = useState(false);
  const [requiredDocList, setRequiredDocList] = useState([]);
  const [suggestedDocsList, setSuggestedDocsList] = useState([]);
  const [additionalDocList, setAdditionalDocList] = useState([]);
  const [slidePane, setSlidePane] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [inactiveEntity, setInactiveEntity] = useState(null);
  const userEmail = authState && authState.email;
  const isInProgress = state.application.status === appStates.IN_PROGRESS;
  const isSubmitted = state.application.status === appStates.SUBMITTED;
  const docTypes = typesState.docTypes;
  const application = state.application;
  const appId = application.typeId;
  const companyInfo = application.companyInfo;
  const isOTCQXUS = appId === 1;
  const isOTCQXBanks = appId === 2;
  const isOTCQXIntl = appId === 3;
  const isOTCQX = isOTCQXUS || isOTCQXBanks || isOTCQXIntl;
  const isOTCQB = appId === 4;
  const isDNS = appId === 5;
  const showNoboNote = isOTCQXUS || isOTCQB || isDNS;
  const isIntlReportStnd = application.reportingStandard === reportingStandards.INTERNATIONAL;
  const canSkip = isDNS && companyInfo.hasOtherExchange && isIntlReportStnd;
  const isShareHolderRequired = isOTCQX || isOTCQB || (!(canSkip));
  const isShareHolderSuggested = canSkip;
  const draftQbCertRequired = isOTCQB;
  const isLogoRequired = isOTCQX;
  const hasChangeInControl = !!(companyInfo && isBoolean(companyInfo.changeInControl) && companyInfo.changeInControl && companyInfo.changeInControlDetail);
  const isCourtAppointedCustodian = companyInfo.isCourtAppointedCustodian;
  const isChangeControlCustodian = isBoolean(isCourtAppointedCustodian);
  const isLetterResignSuggested = isDNS && hasChangeInControl && (isChangeControlCustodian || !isCourtAppointedCustodian);
  const isBoardResolutionSuggested = isDNS && hasChangeInControl && isChangeControlCustodian && isCourtAppointedCustodian;
  const isBoardResolutionRequired = isDNS && !canSkip && hasChangeInControl && isChangeControlCustodian && !isCourtAppointedCustodian;
  const isSuppChangeControlRequired = isDNS && !canSkip && hasChangeInControl && isChangeControlCustodian;
  const isAppApptCustRequired = isDNS && !canSkip && hasChangeInControl && isChangeControlCustodian && isCourtAppointedCustodian;
  const isCrtOrdGrntCustRequired = isDNS && !canSkip && hasChangeInControl && isChangeControlCustodian && isCourtAppointedCustodian;
  const docsList = state.documents;
  const peopleList = application.peopleList;
  const hasAdditionalDocs = additionalDocList && additionalDocList.length > 0;
  const companyName = companyInfo && companyInfo.name;
  const completeCards = state.completeCards;
  const isReady = isDNS ? completeCards && completeCards['change-in-control'] : true;
  const SUPP_DOC_UPDATED = 'suppDocUpdated';
  const requiredDocTitle = isDNS ? `Documents for ${companyName}` : `Required Documents for ${companyName}`;
  const showIncompletes = state.showIncompletes && !supplementalDocumentsCheckComplete(docsList, companyInfo, peopleList, application.reportingStandard);

  useEffect(() => {
    if (application[SUPP_DOC_UPDATED]) {
      saveApplication();
    }
  }, [state.application]);

  useEffect(() => {
    const requiredList = [];
    const suggestedList = [];
    let additionalList = [];
    const shareHolderDoc = docsList && docsList.find(doc => doc.typeId === types.SHAREH_LIST);
    const draftQbCertDoc = docsList && docsList.find(doc => doc.typeId === types.DRAFT_QB_CERT);
    const logoDoc = docsList && docsList.find(doc => doc.typeId === types.LOGO);
    const letterResignDoc = docsList && docsList.find(doc => doc.typeId === types.LETTER_RESIGN);
    const boardResolutionDoc = docsList && docsList.find(doc => doc.typeId === types.BOARD);
    const suppChangeControlDoc = docsList && docsList.find(doc => doc.typeId === types.DSC_CHG_CTRL_EVT);
    const appApptCustDoc = docsList && docsList.find(doc => doc.typeId === types.APP_APPT_CUST);
    const crtOrdGrntCustDoc = docsList && docsList.find(doc => doc.typeId === types.CRT_ORD_GRNT_CUST);

    if (isShareHolderRequired) {
      shareHolderDoc && requiredList.push({ ...shareHolderDoc, isRequired: true });
      !shareHolderDoc && requiredList.push({ typeId: types.SHAREH_LIST, isRequired: true });
    }

    if (draftQbCertRequired) {
      draftQbCertDoc && requiredList.push(draftQbCertDoc);
      !draftQbCertDoc && requiredList.push({ typeId: types.DRAFT_QB_CERT, isRequired: true });
    }

    if (isLogoRequired) {
      logoDoc && requiredList.push({ ...logoDoc, isRequired: true });
      !logoDoc && requiredList.push({ typeId: types.LOGO, isRequired: true });
    }

    if (isSuppChangeControlRequired) {
      suppChangeControlDoc && requiredList.push({ ...suppChangeControlDoc, isRequired: true });
      !suppChangeControlDoc && requiredList.push({ typeId: types.DSC_CHG_CTRL_EVT, isRequired: true });
    }

    if (isBoardResolutionRequired) {
      boardResolutionDoc && requiredList.push(boardResolutionDoc);
      !boardResolutionDoc && requiredList.push({ typeId: types.BOARD });
    }

    if (isShareHolderSuggested) {
      shareHolderDoc && suggestedList.push(shareHolderDoc);
      !shareHolderDoc && suggestedList.push({ typeId: types.SHAREH_LIST });
    }

    if (isLetterResignSuggested) {
      letterResignDoc && suggestedList.push(letterResignDoc);
      !letterResignDoc && suggestedList.push({ typeId: types.LETTER_RESIGN });
    }

    if (isBoardResolutionSuggested) {
      boardResolutionDoc && suggestedList.push(boardResolutionDoc);
      !boardResolutionDoc && suggestedList.push({ typeId: types.BOARD });
    }

    if (isAppApptCustRequired) {
      appApptCustDoc && requiredList.push(appApptCustDoc);
      !appApptCustDoc && requiredList.push({ typeId: types.APP_APPT_CUST });
    }

    if (isCrtOrdGrntCustRequired) {
      crtOrdGrntCustDoc && requiredList.push(crtOrdGrntCustDoc);
      !crtOrdGrntCustDoc && requiredList.push({ typeId: types.CRT_ORD_GRNT_CUST });
    }

    additionalList = docsList && docsList.filter(doc => ((!isShareHolderRequired || !shareHolderDoc || doc.id !== shareHolderDoc.id) &&
      (!draftQbCertRequired || !draftQbCertDoc || doc.id !== draftQbCertDoc.id) &&
      (!isLogoRequired || !logoDoc || doc.id !== logoDoc.id) &&
      (!isShareHolderSuggested || !shareHolderDoc || doc.id !== shareHolderDoc.id) &&
      (!isLetterResignSuggested || !letterResignDoc || doc.id !== letterResignDoc.id) &&
      (!isBoardResolutionRequired || !boardResolutionDoc || doc.id !== boardResolutionDoc.id) &&
      (!isBoardResolutionSuggested || !boardResolutionDoc || doc.id !== boardResolutionDoc.id) &&
      (!isSuppChangeControlRequired || !suppChangeControlDoc || doc.id !== suppChangeControlDoc.id) &&
      (!isAppApptCustRequired || !appApptCustDoc || doc.id !== appApptCustDoc.id) &&
      (!isCrtOrdGrntCustRequired || !crtOrdGrntCustDoc || doc.id !== crtOrdGrntCustDoc.id)
    ));

    setRequiredDocList(requiredList);
    suggestedList && setSuggestedDocsList(suggestedList);
    additionalList && setAdditionalDocList(additionalList);
  }, [docsList]);

  const onPanelClose = () => {
    setError(null);
    setIsUploading(false);
    setSelectedDocType(null);
    setSlidePane(false);
  };

  const onEntityClick = item => {
    if (!isInProgress && !isSubmitted) return;
    if (item.created) return;

    const docType = docTypes.find(type => type.typeId === item.typeId);
    setSelectedDocType(docType);
    setSlidePane(true);
  };

  const entityTitle = doc => {
    if (doc.typeId === types.PERSONAL) return `PIF (${doc.attributes.fullName})`;

    const type = doc && docTypes && docTypes.find(type => type.typeId === doc.typeId);
    return type && type.description;
  };

  const onUpdate = (data, field) => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: field
    });
  };

  const handleRemove = (e, doc) => {
    e.stopPropagation();
    setInactiveEntity(doc.id);
    deleteApplicationDoc(doc.id, application.id, userEmail)
      .then(() => {
        setInactiveEntity(null);

        const list = docsList.map(d => {
          if (d.id === doc.id) d.isDeleted = true;
          return d;
        }).filter(d => d && !d.isDeleted);

        switch (doc.typeId) {
          case types.PERSONAL:
            let updatePeopleList = [...peopleList];
            updatePeopleList = updatePeopleList.map(person => {
              if (person.pifUploadedId === doc.id) {
                person.pifUploadedId = null;
              }
              return person;
            });

            onUpdate(updatePeopleList, 'peopleList');
            break;
        };

        dispatch({
          type: 'SET_DOCUMENTS',
          payload: list
        });

        onUpdate(true, SUPP_DOC_UPDATED);
      })
      .catch(error => {
        const id = new Date().getTime();
        const errorMsg = error && error.response && error.response.data && error.response.data.message;

        setInactiveEntity(null);
        dispatchNotification({
          type: 'FAILED_SAVE_APPLICATION',
          payload: {
            id: id,
            description: `Error Deleting File${errorMsg ? ': ' + errorMsg : ''} ${moment().format('MMMM D, YYYY')}`,
            type: 'error'
          }
        });
      });
  };

  const handleDocumentSubmit = (file, docType, attributes) => {
    const userFullName = [authState.firstName, authState.middleName, authState.lastName];
    const uploaderName = userFullName.join(' ').replace(/\s+/g, ' ').trim();
    setIsUploading(true);
    uploadApplicationDoc(docType, application.id, userEmail, file, { ...attributes, uploaderName })
      .then(d => {
        const id = new Date().getTime();

        const updatedDocsList = docsList ? [...docsList] : [];
        updatedDocsList.push(d);

        dispatch({
          type: 'SET_DOCUMENTS',
          payload: updatedDocsList
        });

        dispatchNotification({
          type: 'ADD_NOTIFICATION',
          payload: {
            id: id,
            description: `Your document has been uploaded. ${moment().format('MMMM D, YYYY')}`,
            type: 'save'
          }
        });

        if (isInProgress) onUpdate(true, SUPP_DOC_UPDATED);

        setTimeout(() => {
          dispatchNotification({
            type: 'REMOVE_NOTIFICATION',
            id: id
          });
        }, 3000);

        onPanelClose();
      })
      .catch(error => {
        const id = new Date().getTime();
        setIsUploading(false);

        const errorMsg = error && error.response && error.response.data && error.response.data.message;

        dispatchNotification({
          type: 'FAILED_SAVE_APPLICATION',
          payload: {
            id: id,
            description: `Error Uploading File${errorMsg ? ': ' + errorMsg : ''} ${moment().format('MMMM D, YYYY')}`,
            type: 'error'
          }
        });
        setError(errors.FILE_UPLOAD);
      });
  };

  const validateComplete = doc => {
    return doc && doc.created;
  };

  const handleHideRemoveButton = doc => {
    const hide = doc && !doc.created;
    return !isInProgress || hide;
  };

  const handleMouseEnter = id => {
    setEntityHover(id);
  };

  const handleMouseLeave = () => {
    setEntityHover(null);
  };

  const renderEntity = (id, item, required = false) => {
    const isComplete = validateComplete(item);
    let entityIcon = 'file';

    if (isComplete) entityIcon = 'fileComplete';
    if (required && showIncompletes && !isComplete) entityIcon = 'incomplete';

    return (
      <>
        <Entity
          title={entityTitle(item)}
          subTitle={isComplete ? 'Complete' : 'Attach Document'}
          confirmRemoveText={entities.CONFIRM_REMOVE_DOCUMENT}
          showIncompletes={required && showIncompletes && !isComplete}
          isComplete={isComplete}
          validateComplete={isComplete}
          icon={entityIcon}
          onClick={() => onEntityClick(item)}
          handleRemove={e => handleRemove(e, item)}
          isInactive={item.id === inactiveEntity}
          hideClose={handleHideRemoveButton(item)} />
        {(item.created && item.typeId !== types.LOGO) && <Tooltip
          target={`#${id}`}
          visible={isEntityHover && isEntityHover === id}
          position='bottom'
          hideOnOutsideClick={false}
        >
          <div>
            <DocumentTooltip title={entityTitle(item)} attributes={item.attributes} />
          </div>
        </Tooltip>}
      </>
    );
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        desc='Please upload the required supplemental documents for this application. You may also upload additional documents as needed or as requested by OTC Markets. Use the Add New Document button to add additional files.' />
      {!isReady && <Label isError>
        <b>
          Change In Control (Section 3) needs to be completed before you are able to access Supplemental Documents
        </b>
      </Label>}
      {isReady && <>
        <div
          className='mbXL'
          onMouseEnter={() => setListFocus(true)}
          onMouseLeave={() => setListFocus(false)}>
          {requiredDocTitle && <div className={cn({
            [styles.incomplete]: showIncompletes
          })}>
            <Title className={styles.inline} title={requiredDocTitle} type='h3' />
            {showIncompletes && <FieldError error={'Required document(s) are missing'} isFocus={listFocus} />}
          </div>}
          <div className={styles.entityList}>
            {requiredDocList && requiredDocList.map((item, i) => {
              const id = `required-${i + 1}`;

              return <div
                key={i}
                id={id}
                onMouseEnter={() => handleMouseEnter(id)}
                onMouseLeave={handleMouseLeave}>
                {renderEntity(id, item, true)}
              </div>;
            })}
            {suggestedDocsList && suggestedDocsList.map((item, i) => {
              const id = `suggested-${i + 1}`;

              return <div
                key={i}
                id={id}
                onMouseEnter={() => handleMouseEnter(id)}
                onMouseLeave={handleMouseLeave}>
                {renderEntity(id, item)}
              </div>;
            })}
          </div>
        </div>
        {showNoboNote && <div className='mbLg'>
          <Label>
            <Title title='NOBO List' type='h3' />
            <p>
              If your shareholder list shows a high concentration held in street name (DTC, CEDE & Co, CDS & Co.), please also provide a recent NOBO List using the Additional Documents upload feature below.
            </p>
          </Label>
        </div>}
        <div className='mbLg'>
          <Title className={styles.inline} title={`Additional Documents for ${companyName}`} type='h3' />
          <div className={styles.entityList}>
            {additionalDocList && additionalDocList.map((item, i) => {
              const id = `additional-${i + 1}`;

              return <div
                key={i}
                id={`${id}`}
                onMouseEnter={() => handleMouseEnter(id)}
                onMouseLeave={handleMouseLeave}>
                {renderEntity(id, item)}
              </div>;
            })}
            {(isInProgress || isSubmitted) && <Entity
              title='Add New Document'
              isAdd
              onClick={() => setSlidePane(true)} />}
            {(!isInProgress && !isSubmitted && !hasAdditionalDocs) && <Entity
              title='No Additional Documents'
              isEmpty />}
          </div>
        </div>
        <SlidePanel
          isOpen={slidePane}
          onClose={onPanelClose}
          title='Add New Document'>
          <AddNewDocument
            defaultCompany={companyName}
            defaultDocType={selectedDocType}
            setSlidePane={setSlidePane}
            errorMsg={error}
            isUploading={isUploading}
            onSubmit={handleDocumentSubmit} />
        </SlidePanel>
      </>}
    </div>
  );
};

SupplementalDocuments.propTypes = {
  name: PropTypes.string
};

export default SupplementalDocuments;
