import React from 'react';
import { appNames } from '../../constants';
import { numToStr } from '../../utils/helper';
import { format } from '../../utils/locale';
import moment from 'moment-timezone';
import { View } from '@react-pdf/renderer';
import { Column, Label, Row, SectionHeader, SectionTitle, Value } from '../components';

const SecurityDetails = ({ data, type }) => {
  const secDetails = data || {};
  const isOTCQXUS = type === appNames.OTCQX_US;
  const isOTCQXBanks = type === appNames.OTCQX_BANKS;
  const isOTCQXIntl = type === appNames.OTCQX_INTL;
  const isOTCQB = type === appNames.OTCQB;
  const isDNS = type === appNames.DNS;
  const securityList = secDetails.securityList && secDetails.securityList.filter(item => {
    let isTraded = false;

    if (isOTCQXUS || isOTCQXBanks || isOTCQXIntl) isTraded = item.isQXtraded;
    if (isOTCQB) isTraded = item.isQBtraded;
    if (isDNS) isTraded = true;

    if (isTraded) return item;
  });

  const hasSecurities = securityList && securityList.length > 0;

  return (
    <>
      <View fixed>
        <SectionHeader text='SECURITY DETAILS' />
      </View>
      {securityList && securityList.map((item, i) => (
        <View break={i > 0} key={`sec-details-${i}`}>
          <View >
            <View fixed>
              <SectionTitle text={`Symbol: ${item.symbol ? item.symbol : ' '}`} emptyText={!item.symbol && 'Empty'} />
            </View>
            <Row>
              <Column size='25'>
                <Label text='Cusip Number' />
                <Value text={item.cusip} />
              </Column>
              <Column size='25'>
                <Label text='Security Type' />
                <Value text={item.type} />
              </Column>
              <Column size='25'>
                <Label text='Security Class' />
                <Value text={item.securityClass} />
              </Column>
            </Row>
            <Row>
              <Column size='100'>
                <Label text='SHARE INFORMATION DATA (AS OF)' />
                <Value text={item.sharesAsOfDate && moment(item.sharesAsOfDate).format('LL')} />
              </Column>
            </Row>
            <Row>
              <Column size='100'>
                <Label text='SHARES AUTHORIZED' />
                {item.isAuthorizedUnlimited && <Value text='Unlimited' /> }
                {!item.isAuthorizedUnlimited && <Value text={numToStr(item.authorizedShares)} type='int' /> }
              </Column>
            </Row>
            <Row>
              <Column size='100'>
                <Label text='TOTAL SHARES OUTSTANDING' />
                <Value text={numToStr(item.sharesOutstanding)} type='int' />
              </Column>
            </Row>
            {(isOTCQXUS || isOTCQXBanks || isOTCQB || isOTCQXIntl) && <>
              <Row>
                <Column size='100'>
                  <Label text='RESTRICTED SHARES' />
                  <Value text={numToStr(item.restrictedShares)} type='int' />
                </Column>
              </Row>
              <Row>
                <Column>
                  <Label text='Unrestricted Shares' />
                  <Value text={numToStr(item.unrestrictedShares)} type='int' />
                </Column>
              </Row>
              <Row>
                <Column size='100'>
                  <Label text='UNRESTRICTED SHARES HELD BY OFFICERS, DIRECTORS, 10% HOLDERS' />
                  <Value text={numToStr(item.unrestrictedSharesAffiliates)} type='int' />
                </Column>
              </Row>
              <Row>
                <Column size='100'>
                  <Label text='NUMBER OF BENEFICIAL SHAREHOLDERS OF AT LEAST 100 SHARES' />
                  <Value text={numToStr(item.beneficialShareholders)} type='int' />
                </Column>
              </Row>
              <Row>
                <Column size='50'>
                  <Label text='PUBLIC FLOAT' />
                  <Value text={numToStr(item.publicFloat)} type='int' />
                </Column>
                <Column size='50'>
                  <Label text='PUBLIC FLOAT %' />
                  <Value text={numToStr(item.publicFloatPercentage)} type='percentage' />
                </Column>
              </Row>
            </>}
            <Row>
              <Column size='100'>
                <Label text='Par Value' />
                <Value text={numToStr(item.parValue)} type='number' />
              </Column>
            </Row>
          </View>
        </View>
      ))}
      {((isOTCQXUS || isOTCQXBanks || isOTCQB) && hasSecurities) && <View>
        <SectionTitle text='VOTING RIGHTS, DIVIDEND PAYMENTS, OTHER PREFERENCES' />
        <Row>
          <Column size='100'>
            <Label text='DO THE SECURITIES ENTITLE HOLDERS TO DIFFERENTIAL VOTING RIGHTS, DIVIDEND PAYMENTS, OR OTHER PREFERENCES?' />
            <Value text={secDetails && format(secDetails.hasVotingRightsDividends, null, 'bool')} />
          </Column>
        </Row>
        {secDetails.hasVotingRightsDividends && <Row>
          <Column size='100'>
            <Label text='DESCRIPTION OF RIGHTS & NAMES OF SHAREHOLDERS' />
            <Value text={secDetails.hasVotingRightsDividendsDetail} />
          </Column>
        </Row>}
      </View>}
      {!hasSecurities && <Value value={null} />}
    </>
  );
};

export default SecurityDetails;
