export const secTypes = [
  'Agency Debt Bond',
  'American Depository Receipts',
  'Common Stock',
  'Corporate Bond',
  'Equity Linked Note',
  'Exchange-Traded Fund',
  'Fund',
  'Global Depository Receipts',
  'New York Registry Shs',
  'Ordinary Shares',
  'Other Security Type',
  'Preferred Stock',
  'Rights',
  'Structured Product',
  'Units',
  'Warrants'
];

export const secClasses = [
  {
    id: 'A',
    name: 'Class A'
  },
  {
    id: 'B',
    name: 'Class B'
  },
  {
    id: 'C',
    name: 'Class C'
  },
  {
    id: 'D',
    name: 'Class D'
  },
  {
    id: 'E',
    name: 'Class E'
  },
  {
    id: 'F',
    name: 'Class F'
  },
  {
    id: 'G',
    name: 'Class G'
  },
  {
    id: 'H',
    name: 'Class H'
  },
  {
    id: 'I',
    name: 'Class I'
  },
  {
    id: 'J',
    name: 'Class J'
  },
  {
    id: 'K',
    name: 'Class K'
  },
  {
    id: 'L',
    name: 'Class L'
  },
  {
    id: 'M',
    name: 'Class M'
  },
  {
    id: 'N',
    name: 'Class N'
  },
  {
    id: 'O',
    name: 'Class O'
  },
  {
    id: 'P',
    name: 'Class P'
  },
  {
    id: 'Q',
    name: 'Class Q'
  },
  {
    id: 'R',
    name: 'Class R'
  },
  {
    id: 'S',
    name: 'Class S'
  },
  {
    id: 'T',
    name: 'Class T'
  },
  {
    id: 'U',
    name: 'Class U'
  },
  {
    id: 'V',
    name: 'Class V'
  },
  {
    id: 'W',
    name: 'Class W'
  },
  {
    id: 'X',
    name: 'Class X'
  },
  {
    id: 'Y',
    name: 'Class Y'
  },
  {
    id: 'X',
    name: 'Class Z' }
];
