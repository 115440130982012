import moment from 'moment-timezone';
import queryString from 'query-string';
import { isNumber } from './validations';
import { appStates, entities } from '../constants';
import { monthsList } from '../constants/months';
import { signSubmitCheckComplete } from '../constants/cardComplete';

export const isAppReadOnly = status => status !== appStates.IN_PROGRESS;

export const getTaxAmount = (appBasicFee, taxRate) => {
  if (taxRate && isNumber(taxRate)) {
    const taxAmount = appBasicFee * (taxRate / 100);
    return taxAmount;
  }
};

export const getCreditCardExpiryMonthYear = (month, year) => {
  if (month && year) {
    const selectedMonth = monthsList.find(item => item.id === month);
    if (selectedMonth) {
      return `${selectedMonth.twoDigitId}/${year.toString().slice(-2)}`;
    }
  }

  return `${month}/${year}`;
};

export const renderFullName = (first, middle, last) => {
  return (first || middle || last) ? `${first || ''}${middle ? ' ' + middle : ''} ${last || ''}` : null;
};

export const renderAddress = (address1, address2, address3, city, state, zip, country) => {
  return `${address1} ${address2} ${address3}${(address1 || address2 || address3) && '\n'}${city} ${state} ${zip}${(city || state || zip) && '\n'}${country}`;
};

export const numToStr = value => isNumber(value) ? value.toString() : null;

export const createID = array => {
  const id = Math.floor(Math.random() * 90000) + 10000;
  if (array && array.find(item => item.id === id)) {
    createID(array);
  } else {
    return id;
  }
};

export const isSameOrBefore = date => date ? moment(date).isSameOrBefore(new Date()) : true;

export const getFilteredCompanyList = data => {
  const companyList = data.map(app => { return { Name: app.companyName || app.Name, symbol: app.primarySymbol || app.symbol }; }).sort((a, b) => (a.Name && a.Name.toLowerCase() > b.Name && b.Name.toLowerCase()) ? 1 : -1).map(item => {
    return {
      id: createID(),
      ...item
    };
  });

  const filteredArr = companyList.reduce((companies, current) => {
    const x = companies.find(item => item.Name === current.Name);
    if (!x) {
      return companies.concat([current]);
    } else {
      return companies;
    }
  }, []);

  return filteredArr;
};

export const getQueryObj = search => {
  return queryString.parse(search);
};

export const createQuery = params => {
  const query = queryString.stringify(params, { skipNull: true, skipEmptyString: true });
  return query;
};

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

export const getTier = data => {
  let tier = (data && (data.tierId || data.tierCode || data.tierGroup));
  if (!isNaN(parseInt(tier))) tier = parseInt(tier);

  let tierIcon = null;

  switch (tier) {
    case -1:
    case 'CE':
      tierIcon = 'caveat-emptor';
      break;

    case 1:
    case 2:
    case 5:
    case 6:
    case 'OTCQX_US':
    case 'OTCQX_BANKS':
    case 'OTCQX_INTL':
    case 'OTCQX':
    case 'QX':
    case 'QXUSP':
    case 'QXUS':
    case 'QXIP':
    case 'QXI':
      tierIcon = 'qx'; // QX
      break;

    case 10:
    case 'OTCQB':
    case 'QB':
    case 'DQ':
      tierIcon = 'qb'; // QB
      break;

    case 20:
    case 'PS':
    case 'PC':
      tierIcon = 'ps'; // Pink
      break;

    case 21:
    case 'PL':
      tierIcon = 'yield'; // Pink Limited Info
      break;

    case 22:
    case 'PN':
      tierIcon = 'stop'; // Pink No Info
      break;

    case 30:
    case 'OO':
    case 'GM':
      tierIcon = 'caution'; // Grey Market
      break;

    case 40:
    case 'EM':
      tierIcon = 'expert-tier'; // Expert Tier
      break;

    default:
      break;
  }

  return tierIcon;
};

export const entityTitle = person => {
  const hasTitles = person && person.title1;
  const titleList = [];

  if (hasTitles) titleList.push(person.title1);
  if (person.title2) titleList.push(person.title2);
  if (person.title3) titleList.push(person.title3);
  if (person.title4) titleList.push(person.title4);

  let title = `${person.firstName} ${person.lastName}`;
  if (hasTitles) title = title + `, ${titleList.join(', ')}`;

  if (!title || title.trim().length === 0) return entities.NO_NAME;

  return title;
};

export const isCorporateEntity = (person, corporateEntityList) => {
  return person.isCorporateEntity && corporateEntityList && corporateEntityList.find(entity => entity.owners.includes(person.id));
};

export const getBackgroundList = (people, corporateEntityList, appType) => {
  const qxPeopleList = people && people.filter(person => person.isOfficer || person.isDirector || person.isControl || isCorporateEntity(person, corporateEntityList));
  const qbPeopleList = people && people.filter(person => person.isOfficer || person.isDirector || person.isControl || isCorporateEntity(person, corporateEntityList) || person.isPrimaryContact || person.isAuthorizedUser);
  const dnsPeopleList = people && people.filter(person => person.isOfficer || person.isDirector || person.isControl || isCorporateEntity(person, corporateEntityList) || person.isPrimaryContact || person.isPrimaryBillingContact || person.isAuthorizedUser);

  switch (appType) {
    case 1:
    case 2:
    case 3:
    case 'otcqxus':
    case 'otcqxbanks':
    case 'otcqxintl':
      return qxPeopleList;
    case 4:
    case 'otcqb':
      return qbPeopleList;
    case 5:
    case 'dns':
      return dnsPeopleList;
  }
};
