import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { urls } from '../../../constants';
import { isBoolean } from '../../../utils/validations';
import { ApplicationContext } from '../../../context/Application.context';
import ApplicationDescription from '../../ApplicationDescription';
import QuestionCard from '../../QuestionCard';

const DESC = <>Select any additional service the company would like to subscribe to.<br />For information on fees, see <a href={urls.FEE_SCHEDULE} target='_blank' rel='noopener noreferrer'>Fee Schedule</a>.</>;

const AdditionalServices = ({ name }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const application = state.application;
  const appTypeName = application.typeDesc;
  const additionalServices = application.additionalServices;
  const showIncompletes = state.showIncompletes;
  const isReadOnly = state.readOnly;
  const BSM_QUESTION_TITLE = 'Blue Sky Monitoring Service';
  const VIC_QUESTION_TITLE = 'Virtual Investor Conference';
  const RTL2_QUESTION_TITLE = 'Real-Time Level 2 Quote Display Service';

  const questions = [
    {
      title: BSM_QUESTION_TITLE,
      subTitle: () => <>
        <p>
          The Blue Sky Monitoring Service offers companies a customized daily audit of their Blue Sky compliance status in each U.S. state or territory including a daily view of information that is distributed to broker-dealers and guidance on ways to maximize compliance through available exemptions.
        </p>
        <p>
          For more information on this service, please see <a href={urls.BLUE_SKY} target='_blank' rel='noopener noreferrer'>Blue Sky</a>.
        </p>
      </>,
      fieldName: 'isInterestedInBSM',
      appTypes: [4]
    },
    {
      title: VIC_QUESTION_TITLE,
      subTitle: () => <>
        <p>
          {appTypeName} companies are invited to present at our Virtual Investor Conference (VIC) at a reduced rate. The VIC is an effective way for companies to reach the investment community, without the cost and time of travel.
        </p>
        <p>
          For more information on this service, please see <a href={urls.VIC} target='_blank' rel='noopener noreferrer'>Virtual Investor Conferences</a> page on OTCMarkets.com.
        </p>
        <p>
          If you select this service, a member of our team will reach out to you with a separate form and invoice for the service.
        </p>
      </>,
      fieldName: 'isInterestedInVIC',
      appTypes: [1, 2, 3, 4]
    },
    {
      title: RTL2_QUESTION_TITLE,
      subTitle: () => <>
        <p>
          Deliver insight into available liquidity, allowing professional and retail investors to make informed investment decisions. <a href={urls.REAL_TIME_LEVEL_2} target='_blank' rel='noopener noreferrer'>Real-Time Level 2 Quotes</a>, which includes all broker-dealer bid/ask prices and sizes, are displayed on a company's quote page on www.otcmarkets.com and can be integrated onto your corporate site.
        </p>
      </>,
      fieldName: 'isRealTimeLevel2',
      appTypes: [5]
    }
  ];

  const onUpdate = data => {
    dispatch({
      type: 'UPDATE_APPLICATION',
      payload: data,
      appField: 'additionalServices',
      card: 'additional-services'
    });
  };

  const onValueChanged = (e, field) => {
    let updateAdditionalServices = additionalServices || {};
    updateAdditionalServices[field] = e.value;
    onUpdate(updateAdditionalServices);
  };

  const handleQuestionClick = (value, field) => {
    let updateAdditionalServices = additionalServices || {};
    updateAdditionalServices[field] = value;
    onUpdate(updateAdditionalServices);
  };

  const renderQuestons = () => {
    const applicationQuestions = questions.filter(q => q.appTypes.includes(application.typeId));
    return applicationQuestions.map((q, i) => {
      return <QuestionCard
        key={i}
        item={q}
        section={additionalServices}
        readOnly={isReadOnly}
        isValid={showIncompletes ? isBoolean(additionalServices && additionalServices[q.fieldName]) : true}
        showIncompletes={showIncompletes}
        handleQuestionClick={handleQuestionClick}
        onValueChanged={onValueChanged} />;
    });
  };

  return (
    <div>
      <ApplicationDescription
        title={name}
        desc={DESC} />
      {renderQuestons()}
    </div>
  );
};

AdditionalServices.propTypes = {
  name: PropTypes.string
};

export default AdditionalServices;
