import React from 'react';
import { appTypeIds, urls, reportingStandards } from '.';

const SEC = {
  id: reportingStandards.SEC,
  desc: 'The company is subject to the reporting obligations under Section 13 or 15(d) of the Exchange Act.'
};

const REGULATION = {
  id: reportingStandards.REGULATION,
  desc: 'The company is subject to the reporting obligations under Tier 2 of Regulation A under the Securities Act.'
};

const ALTERNATIVE = {
  id: reportingStandards.ALTERNATIVE,
  component: () => <>
    The company is not subject to SEC or Regulation A reporting requirements, and intends to make disclosure pursuant to the <a href={urls.OTCQX_OTCQB_GUIDELINES} target='_blank' rel='noopener noreferrer'>OTCQX and OTCQB U.S. Disclosure Guidelines.</a>
  </>
};

const INTERNATIONAL = {
  id: reportingStandards.INTERNATIONAL,
  desc: 'The Company is an International Company listed on a non-U.S. stock exchange and is compliant with the disclosure requirements of Exchange Act Rule 12g3-2(b).'
};

const DNS_BANK = {
  id: reportingStandards.BANK,
  component: () => <>
    The Company is not subject to SEC or Reg A Reporting Requirements, but will provide all information required to be made publicly available pursuant to the requirements of the Company’s Bank Regulator and intends to make disclosure pursuant to the  <a href={urls.DIS_GUIDANCE_BANK_REPORT_COMP} target='_blank' rel='noopener noreferrer'>Disclosure Guidelines for Bank Reporting Companies</a>
  </>,
  details: {
    desc: 'Provide the name(s) of the primary federal regulator(s) with oversight of the Company’s business activities (any or all of the Federal Deposit Insurance Corporation (FDIC), Federal Reserve, or Office of the Comptroller of the Currency (OCC))',
    label: 'Name(s)',
    placeholder: 'Provide name(s) of your primary federal regulator here.'
  }
};

const DNS_ALTERNATIVE = {
  id: reportingStandards.ALTERNATIVE,
  component: () => <>
    The company is not subject to SEC or Regulation A reporting requirements, and intends to make disclosure pursuant to the <a href={urls.PINK_DNS_GUIDLINES} target='_blank' rel='noopener noreferrer'>OTC Pink Basic Disclosure Guidelines.</a>
  </>
};

const DNS_SEC = {
  id: reportingStandards.SEC,
  desc: 'The company is subject to the reporting obligations under Section 13 or 15(d) of the Exchange Act. Please note that delinquent SEC filers have to either become current in their filings with the SEC or de-register and follow the steps for Alternate Reporting to get to Current Information.'
};

const reportingStandardOtcxUsOptions = [SEC, REGULATION, ALTERNATIVE];

const reportingStandardOtcxBanksOptions = [SEC, REGULATION];

const reportingStandardOtcxIntlOptions = [INTERNATIONAL, SEC, REGULATION];

const reportingStandardOtcqbOptions = [SEC, INTERNATIONAL, REGULATION, ALTERNATIVE];

const reportingStandardDnsOptions = [
  DNS_ALTERNATIVE, DNS_BANK, INTERNATIONAL, DNS_SEC, REGULATION

];

export const reportingStandardOptionsMap = new Map([
  [appTypeIds.OTCQX_US, reportingStandardOtcxUsOptions],
  [appTypeIds.OTCQX_BANKS, reportingStandardOtcxBanksOptions],
  [appTypeIds.OTCQX_INTL, reportingStandardOtcxIntlOptions],
  [appTypeIds.OTCQB, reportingStandardOtcqbOptions],
  [appTypeIds.DNS, reportingStandardDnsOptions]

]);

export const US_BANK = {
  id: reportingStandards.BANK,
  desc: 'The Company is not subject to SEC or Reg A Reporting requirements, but will provide all information required to have been made publicly available pursuant to the requirements of the Company’s Bank Regulator.',
  details: {
    desc: 'Provide the name(s) of the primary federal regulator(s) with oversight of the Company’s business activities (any or all of the Federal Deposit Insurance Corporation (FDIC), Federal Reserve, or Office of the Comptroller of the Currency (OCC)).',
    label: 'Name(s)',
    placeholder: 'Provide name(s) of your primary federal regulator here.'
  }
};
